import React, { useContext, useState, useEffect } from "react";
import { OwnContext } from "../context/OwnContextProvider";
import { refreshToken } from "../authentication/zohoLogin";
import { setTokens } from "../storage/storage";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function PrivateRoute({ children, ...rest }) {
  const context = useContext(OwnContext);
  const {
    access_token,
    refresh_token,
    expires_in,
    creation_time,
    updateState,
  } = context;

  const { authService } = rest;

  const { skippable } = rest;
  const { hasRole } = rest;
  const { publicRoute } = rest;

  const [isLoggedIn, setIsLoggedIn] = useState();
  const [roleCheck, setRoleCheck] = useState(false);
  const [roles, setRoles] = useState([]);

  function isPromise(promise) {
    return !!promise && typeof promise.then === "function";
  }

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleResponse = (data) => {
    if (
      data["access_token"] !== undefined &&
      data["refresh_token"] !== undefined
    ) {
      updateState(context, {
        ...context,
        access_token: data["access_token"],
        refresh_token: data["refresh_token"],
        creation_time: Date.now(),
        expires_in: data["expires_in"],
        isLoggedIn: true,
      });
    }
  };

  const handleZohoAuth = () => {
    if (access_token && refresh_token) {
      // We need to check if the token is valid
      var creationDate = new Date(creation_time);
      creationDate.setHours(creationDate.getHours() + 1);
      if (creationDate.getTime() > Date.now()) {
        // The token is valid
        return true;
      } else {
        // The token is expired, we need to refresh the token
        return refreshToken()
          .then((response) => {
            _handleResponse(response);
            return true;
          })
          .catch((e) => {
            // Failed to fetch
            return false;
          });
      }
    }
    return false;
  };

  const handleAuth = async () => {
    const zohoAuth = handleZohoAuth();
    let result = false;
    await (async function () {
      if (isPromise(zohoAuth)) {
        // In the zoho auth there was a token refresh attempt so we got a promise
        await zohoAuth
          .then((isZohoLoggedIn) => {
            if (isZohoLoggedIn) {
              //The user us logged in to zoho as well
              result = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // We got a boolean value, so we can set the value directly to the state variable
        result = zohoAuth;
      }
      // if prop then user must have role
      if (hasRole?.length > 0) {
        const getRoles = async () => {
          let _ = await authService.getRoles();
          _ = _[0];
          setRoles(_);
          if (_.filter((role) => hasRole.includes(role)).length > 0) {
            // Sufficient roles
            setRoleCheck(true);
          }
        };
        await getRoles();
      }
    })();

    setIsLoggedIn(result);
    setIsLoading(false);
  };

  const renderFunction = () => {
    if (isLoggedIn === true) {
      if (skippable) {
        return <Navigate to="/home" />;
      }
      if (hasRole?.length === 0 || !hasRole) {
        return children;
      }
      if (roleCheck) {
        return children;
      }
      if (!roleCheck) {
        navigate(-1);
        return "";
      }
    }
    if (publicRoute) {
      return children;
    }
    return <Navigate to="/login" />;
  };

  return isLoading ? <div></div> : renderFunction();
}
