import { createSlice } from "@reduxjs/toolkit";

export const validationErrorsSlice = createSlice({
  name: "validationErrors",
  initialState: {
    value: [],
  },
  reducers: {
    setErrors: (state, action) => {
      state.value = action.payload;
    },
    clearErrors: (state) => {
      state.value = [];
    },
    pushError: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setErrors, clearErrors, pushError } =
  validationErrorsSlice.actions;

export default validationErrorsSlice.reducer;
