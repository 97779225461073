import Typography from "@mui/material/Typography";
import ErrorComponent from "../ErrorComponent";

function ToolDisabled(props) {
  const { validationErrors } = props;
  console.log(validationErrors);
  return (
    <div className="drag-and-drop disabled">
      {validationErrors?.length == 0 ? (
        <Typography
          component="h3"
          sx={{
            flexGrow: 1,
            color: "var(--pc-grey)",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          Sorry, the projects you are assigned to are{" "}
          <strong>not compatible with this tool</strong>.
        </Typography>
      ) : (
        <ErrorComponent errors={validationErrors}></ErrorComponent>
      )}
    </div>
  );
}

export default ToolDisabled;
