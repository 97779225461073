import "./App.css";

import { useEffect, useState, useContext } from "react";
import { OwnContext } from "./context/OwnContextProvider";
import Home from "./screens/Home";

import { HubConnectionBuilder } from "@microsoft/signalr";

const AppSettingsService = require("./services//app-settings-service");
let appSettingsService = new AppSettingsService();

const App = (props) => {
  const context = useContext(OwnContext);
  const { access_token, refresh_token, expires_in, creation_time } = context;
  const [uploadProgress, setUploadProgress] = useState(null);
  const [connection, setConnection] = useState(null);

  const [exportIsLoading, setExportIsLoading] = useState(false);

  useEffect(() => {
    initializeSignalRConnection();
  }, []);
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log(
            "SignalR Connected! URL: " +
              appSettingsService.GetWebApiOrigin() +
              "/progress"
          );

          connection.on("taskProgressChanged", (message) => {
            setUploadProgress({
              progress: message.split("/")[0],
              maximum: message.split("/")[1],
            });
          });

          connection.on("exportDownloadLinkGenerated", async (message) => {
            console.log(message);
            await props.apiService
              .callApi(
                "/ExportTimesheets/download/" + message,
                null,
                "GET",
                {
                  "Content-Type": "application/json",
                  Accept: "application/zip",
                },
                { responseType: "arraybuffer" }
              )
              .then((response) => {
                if (response.status === 200) {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", message + ".zip");
                  document.body.appendChild(link);
                  link.click();
                }
              })
              .finally(() => setExportIsLoading(false));
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  const initializeSignalRConnection = async () => {
    let token;
    await props.authService
      .getToken()
      .then((result) => {
        token = result;
      })
      .catch((e) => {
        token = null;
      });

    const newConnection = new HubConnectionBuilder()
      .withUrl(appSettingsService.GetWebApiOrigin() + "/progress", {
        accessTokenFactory: () => token,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  };
  /**
   * Handling the response in requestToken and in refreshToken
   * @param {JSON} data the json data returned from fetch
   */

  /**
   * Function which runs at the component creation.
   */
  useEffect(() => {
    console.log(
      `Refresh token: ${refresh_token}\nAccess token: ${access_token}\nExpires in: ${expires_in}\nCreation time: ${creation_time}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="App page">
        <Home
          authService={props.authService}
          apiService={props.apiService}
          uploadProgress={uploadProgress}
          exportIsLoading={exportIsLoading}
          setExportIsLoading={setExportIsLoading}
        />
      </div>
    </>
  );
};

export default App;
