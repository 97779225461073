import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import "../style/Screens/Error.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ErrorPage() {
  const errorData = useSelector((state) => state.beData.serverErrorData);
  const supportLink = useSelector((state) => state.beData.supportLink);
  return (
    <div className="page">
      <header>
        <h1>An error occurred</h1>
      </header>
      <main style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className="error-page__data" style={{ color: "red" }}>
          {errorData === null ? (
            <div style={{ textAlign: "center" }}>
              Unable to connect to the backend for more information.
            </div>
          ) : (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <div className="error-page__data__message">
                {errorData?.message}
              </div>
              {errorData?.errorId && (
                <div className="error-page__data__message">
                  Error id: {errorData?.errorId}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="error-page__action">
          <Typography variant="h4" fontSize="medium">
            Please open the support form and provide the information specified
            in this page. Also include the error id (if present).
          </Typography>
          <Button
            href={supportLink}
            variant="contained"
            color="secondary"
            target="_blank"
          >
            Open support form
          </Button>
        </div>
      </main>
    </div>
  );
}

export default ErrorPage;
