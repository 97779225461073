import { configureStore, combineReducers } from "@reduxjs/toolkit";
import uploadedTimeLogsReducer, {
  resetTimeLogs,
} from "./reducers/uploadedTimeLogsSlice";
import validationErrorsReducer, {
  clearErrors,
} from "./reducers/validationErrorsSlice";
import progressStageReducer, { setStage } from "./reducers/progressStageSlice";
import filesReducer, { clearFiles } from "./reducers/filesSlice";
import beDataReducer from "./reducers/beDataSlice";
import { loadState } from "./storage/browserStorage";

const combinedReducer = combineReducers({
  uploadedTimeLogs: uploadedTimeLogsReducer,
  validationErrors: validationErrorsReducer,
  progressStage: progressStageReducer,
  files: filesReducer,
  beData: beDataReducer,
});

const resetState = () => ({ type: "reset" });

const rootReducer = (state, action) => {
  if (action.type === "reset") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const resetTimeLogsRelatedSlices = () => {
  return (dispatch) => {
    dispatch(resetTimeLogs());
    dispatch(clearErrors());
    dispatch(clearFiles());
    dispatch(setStage(0));
  };
};

export default configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
});

export { resetState, resetTimeLogsRelatedSlices };
