const hasRole = (account, role) => {
  const roleFullName = `Timesheets${role}Group`;

  const claims = account.idTokenClaims;
  if (!claims || !claims.roles) {
    return false;
  }
  return claims.roles.includes(roleFullName);
};

export { hasRole };
