import React from "react";
import "../style/Components/SuccessComponent.scss";
import Button from "@mui/material/Button";
import icon_green from "../assets/icon_green.svg";
import { Typography } from "@mui/material";

const SuccessComponent = ({ ...props }) => {
  const { errors, progressStage } = props;

  const getMessage = () => {
    if (progressStage < 20) {
      return messageAsHtml(
        "Your time logs have been uploaded <span>successfully.</span>"
      );
    }
    return "Timesheet submitted successfully";
  };

  function messageAsHtml(message) {
    return <div dangerouslySetInnerHTML={{ __html: `${message}` }} />;
  }

  return (
    <div className="SuccessComponent">
      <img src={icon_green} width="80" height="80" alt="Checkmark"></img>
      <Typography component="h1" fontSize="30px">
        {getMessage()}
      </Typography>
      {progressStage === 11 && (
        <div>
          <div className="severe-error">
            However, the timesheet can not be submitted
          </div>
        </div>
      )}
      {progressStage === 12 && (
        <div>
          <div className="severe-error">
            However, not all time logs were created successfully. Please review
            the following errors:
          </div>
        </div>
      )}
      {progressStage === 10 && (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={props.handleSubmitTimesheet}
        >
          Submit Monthly Timesheet
        </Button>
      )}
    </div>
  );
};

export default SuccessComponent;
