import * as React from "react";

import { useEffect, useState, useContext } from "react";
import { OwnContext } from "../context/OwnContextProvider";

import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const HelloMessage = (props) => {
  const [name, setName] = useState(null);
  const [roles, setRoles] = useState(null);
  const context = useContext(OwnContext);

  const authService = props.authService;

  const handleChange = (event) => {
    props.onProjectNameChange(event.target.value);
  };

  useEffect(() => {
    async function getAccount() {
      const account = await authService.getAccount();
      setName(account?.name);
      setRoles(
        account?.idTokenClaims.roles
          ?.map((_) => _.substring(10, _.length - 5))
          .join(", ") || "IC"
      );
    }
    getAccount();
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      {name && (
        <div style={{ display: "inline-block", flex: "1" }}>
          <Typography
            component="h2"
            sx={{
              display: "inline-flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: "100%",
            }}
          >
            <div>
              Hello, <strong>{name}</strong>.
            </div>
            <div>
              {props.projects.length > 0
                ? "Your assigned projects: "
                : "Unfortunately, this tool does not support any of your assigned projects. "}
              {props.projects.length > 0 && (
                <Select
                  value={props.project}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    MenuProps: { disableScrollLock: true },
                  }}
                  size="small"
                  sx={{ marginTop: "1rem", width: "100%", maxWidth: 300 }}
                >
                  {props.projects.map((project) => (
                    <MenuItem value={project} key={project.projectId}>
                      {project.projectName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          </Typography>
        </div>
      )}
      {props.projects.length > 0 && (
        <Typography
          component="h2"
          sx={{
            color: "var(--pc-grey)",
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "0.5rem",
          }}
        >
          <div>
            Please use this tool to upload the timesheet used to your client.
          </div>
          <div>
            For more information click here:
            <Link to="/help" target="_blank">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                sx={{
                  marginLeft: "10px",
                  borderWidth: "2px",
                  textDecoration: "none",
                  minWidth: "0",
                  lineHeight: "25px",
                  padding: "3px 21px",
                  borderRadius: "10px",
                  fontSize: 16,
                  "&:hover": { borderWidth: "2px" },
                }}
              >
                help
              </Button>
            </Link>
          </div>
        </Typography>
      )}
    </div>
  );
};

export default HelloMessage;
