import React from "react";
import "../style/Components/ErrorComponent.scss";
import Error from "./ui/Error";

const ErrorComponent = ({ ...props }) => {
  const { errors, handleConfirmFile } = props;

  const disableUpload = () => {
    return (
      errors.filter((err) => err.severity === "Severe").length > 0 ||
      errors.filter((err) => err.severity === "Reject").length > 0
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        gap: 20,
        marginTop: "2.5rem",
      }}
    >
      {errors?.map((error, i) => (
        <Error
          error={error}
          key={i}
          handleConfirmFile={props.handleConfirmFile}
          handleCancel={props.handleCancel}
          confirmExtendTimesheet={props.confirmExtendTimesheet}
          progressStage={props.progressStage}
          handleRefreshValidations={props.handleRefreshValidations}
          disableUpload={disableUpload()}
        />
      ))}
    </div>
  );
};

export default ErrorComponent;
