import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import dragAndDropImg from "../Images/DragAndDropZone.png";
import successfulUploadImg from "../Images/SuccessfulUpload.png";
import uploadErrorImg from "../Images/UploadError.png";
import "../App.css";
import "../style/Screens/HelpPage.scss";

import landingPageImg from "../assets/img/help__landing-page.jpg";
import zohoConsentImg from "../assets/img/help__zoho-consent.jpg";
import appHomeImg from "../assets/img/help__app-home.jpg";
import dragAndDroppingImg from "../assets/img/help__drag-and-dropping.jpg";
import uploadSuccessImg from "../assets/img/help__upload-success.jpg";
import submitSuccessImg from "../assets/img/help__submit-success.jpg";
import minorErrorImg from "../assets/img/help__minor-error.jpg";
import refreshButton from "../assets/img/help__refresh-button.jpg";

import { useSelector } from "react-redux";

export default function Home() {
  const validClients = useSelector((state) =>
    state.uploadedTimeLogs.supportedClients
      .filter((_) => !_.test)
      .map((_) => _.name)
  );

  const timesheetsScreenshots = [
    {
      client: "EON",
      screenshots: [
        "12-months",
        "DSP",
        "leistungsnachweis-10",
        "leistungsnachweis-16",
        "leistungsnachweis-43",
      ],
      text: "For E.ON the tool is supporting 5 different kind of timesheet formats. Please check the screenshots below.",
    },
    {
      client: "powercloud",
      screenshots: ["tempo-excel"],
      text: "For powercloud the tool is supporting the timesheet that you can extract from powercloud internal tool (Tempo). The tempo timesheet has to be in a excel format (<strong>only new format excel (.xlsx) is accepted</strong>) and has to be as in the screenshot below.",
    },
    {
      client: "Aily",
      screenshots: ["project-times-excel", "time-report-detail-csv"],
      text: "For Aily the tool is supporting the timesheet that you can extract from Aily’s internal tool (ZEP). You can download the correct Zep timesheet format by opening Zep and going to Reports -> Project Time Reports -> Time Report Details. The Zep timesheet has to be in an excel format. Time Report Detail csv file is also supported.",
    },
    {
      client: "IBM DE",
      screenshots: ["blue-harmony"],
      text: "For IBM DE the tool is supporting only the excel timesheet format below.",
    },
  ];

  return (
    <div className="page" style={{ gap: "22px" }}>
      <div>
        <Typography
          variant="h1"
          component="div"
          sx={{ fontSize: "2rem" }}
          gutterBottom
        >
          Timesheet Automation Tool
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              display: "inline-block",
              fontSize: "26px",
            }}
          >
            Getting started
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            component="span"
            sx={{
              flexGrow: 1,
              color: "var(--pc-grey)",
              whiteSpace: "nowrap",
            }}
          >
            For timesheet upload click here:
          </Typography>
          <Link to="/home">
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                marginLeft: "10px",
                borderWidth: "2px",
                textDecoration: "none",
                minWidth: "0",
                lineHeight: "25px",
                padding: "3px 21px",
                borderRadius: "10px",
                fontSize: 16,
                "&:hover": { borderWidth: "2px" },
              }}
            >
              upload
            </Button>
          </Link>
        </div>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          When first opening the app, you will be greeted with a landing page.
          The app <strong>requires</strong> your authorization to sign into your{" "}
          <strong>Microsoft</strong> account (for personal information and
          roles) as well your <strong>Zoho</strong> Account (to create time logs
          and timesheets).
        </Typography>
        <img src={landingPageImg} />
        <Typography component="caption" variant="caption">
          Click the Log In button to proceed with the authentication process.
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Once you logged into Microsoft, you will be redirected to Zoho where
          you will need to consent about using the Zoho People API through the
          Timesheets Automation Tool.
        </Typography>
        <img src={zohoConsentImg} />
        <Typography component="caption" variant="caption">
          Click Accept
        </Typography>
      </div>
      <div className="help-page__section-header">
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            display: "inline-block",
            fontSize: "26px",
          }}
        >
          Prerequisites
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Before using this tool please make sure you have available the correct
          timesheet format. Below you will find instructions for each of the
          supported timesheets.
        </Typography>
        <div className="help-page__timesheets-screenshots">
          {timesheetsScreenshots.map((_) => (
            <div className="help-page__timesheets-screenshots__screenshot">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  flexGrow: 1,
                  display: "inline-block",
                  fontSize: "20px",
                }}
              >
                {_.client}
              </Typography>
              <Typography
                component="p"
                dangerouslySetInnerHTML={{ __html: _.text }}
              />
              <div className="help-page__timesheets-screenshots__screenshot__images">
                {_.screenshots.map((s) => (
                  <img
                    src={
                      window.location.origin +
                      "/assets/img/timesheets-screenshots/" +
                      _.client +
                      "__" +
                      s +
                      ".jpg"
                    }
                    key={s}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="help-page__section-header">
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            display: "inline-block",
            fontSize: "26px",
          }}
        >
          Uploading a timesheet file
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Now that you've authenticated, you are presented with the app's home
          screen. Besides a small <strong>welcome message</strong>, you will
          find a dropdown containing all your <strong>accepted projects</strong>
          , an hourly{" "}
          <strong>forecast for the current and previous months</strong> based on
          your daily working hours, a <strong>table</strong> containing your
          timesheets, and a <strong>drop zone</strong> used to drag-and-drop
          timesheet files.
        </Typography>
        <img src={appHomeImg} />
        <Typography component="caption" variant="caption">
          App's home screen
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Start by making sure the selected project in the dropdown corresponds
          to the project for your client timesheet file. Supported projects are
          based on the <strong>client</strong>. Currently supported clients
          include: <strong>{validClients.join(", ")}</strong>.
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Once content with the project, start the process by drag-and-dropping
          your client timesheet file in <strong>.xlsx</strong> or{" "}
          <strong>.csv</strong> format.
        </Typography>
        <img src={dragAndDroppingImg} />
        <Typography component="caption" variant="caption">
          The drop zone will highlight when dragging your file
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          After dropping the file, the app wil check that your timesheet format
          is supported. If your timesheet format or project client is not
          currently supported, it might become accepted at a future date.
        </Typography>
        <Typography component="p">
          An accepted file will be converted by the app into a{" "}
          <strong>list of time logs</strong>. Said time logs are then verified
          against the following conditions:
        </Typography>
        <ul>
          <Typography component="li">
            You have not worked more than 10 hours in a day
          </Typography>
          <Typography component="li">
            You are not trying to create time logs in a day that was already
            logged in Zoho
          </Typography>
          <Typography component="li">
            You are not submitting hours for a date registered as vacation in
            Zoho
          </Typography>
        </ul>
        <Typography component="p">
          <strong>
            A later section will be dedicated to explaining validation errors
            and approaches on how to handle them
          </strong>
          .
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Once your time logs have been created, the app will{" "}
          <strong>automatically log all the work to Zoho</strong> and present
          you with a success message. The option to{" "}
          <strong>submit your timesheet</strong> can be triggered by clicking
          the Submit Monthly Timesheet button.{" "}
          <strong>Only monthly timesheets are accepted.</strong>
        </Typography>
        <img src={uploadSuccessImg} />
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Submitting the timesheet will display a new message stating the
          timesheet creation. The process <strong>ends here</strong> and you are
          free to close the app and return the following month, the process
          being reset.
        </Typography>
        <img src={submitSuccessImg} />
      </div>
      <div className="help-page__section-header">
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            display: "inline-block",
            fontSize: "26px",
          }}
        >
          Handling Errors
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Errors reported by the app could be broken into three categories:
        </Typography>
        <Typography component="li">
          <strong>Employee profile error</strong>: occurs when your Zoho
          employee profile is missing some required fields{" "}
          <strong>(Date of joining, Daily working hours)</strong>. If you see
          this error{" "}
          <strong>
            submit a ticket to{" "}
            <a href="https://support.principal33.com/" target="_blank">
              support.principal33.com
            </a>{" "}
            immediately
          </strong>
          .
        </Typography>
        <Typography component="li">
          <strong>File error</strong>: occurs when your timesheet file's
          extension is not accepted, or the file's format is not recognized by
          the app. If you are uploading multiple files that log different
          months, a file error will also be displayed.{" "}
          <strong>
            File errors will not continue the process until a proper file is
            drag-and-dropped
          </strong>
          .
        </Typography>
        <Typography component="li">
          <strong>Time logs error</strong>: the generated time logs are
          validated by the app to meet the four conditions mentioned above. The
          app will offer options depending on the error on how to remedy the
          problem and proceed with the process. Depending on the severity of the
          issue,{" "}
          <strong>you might not be allowed to submit your timesheet</strong>.
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Solving time log errors is accomplished by clicking on the buttons
          below the error message. Clicking a button that links to Zoho will
          await a <strong>refresh prompt</strong>, where after fixing the
          problems in the Zoho Web App, you can refresh the validations. The
          process can also be cancelled at any time if you so desire by clicking
          the <span style={{ color: "red" }}>X</span> button on the top-right
          corner of the drop zone.
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          Working more than 10 hours or having already submitted time logs in
          certain days classify as <strong>minor errors</strong>. You are still
          allowed to continue your process by clicking the{" "}
          <strong>Upload time logs anyway</strong> button.
        </Typography>
        <img src={minorErrorImg} />
        <Typography component="caption" variant="caption">
          A minor error, displayed in orange color as opposed to red for{" "}
          <strong>severe errors</strong>
        </Typography>
      </div>
      <div className="help-page__section">
        <Typography component="p">
          The other two time log errors, related to working in vacation and
          missing work days from the file are considered{" "}
          <strong>severe errors</strong> by the app and will not continue the
          process until they are addressed.
        </Typography>
        <Typography component="p">
          To fix the <strong>working in vacation</strong> issue, two solutions
          are proposed: the{" "}
          <strong>Review your timesheet document and upload again</strong>{" "}
          button will cancel the process so you can open your file in an editor
          and remove the work hours from you vacation days, while the{" "}
          <strong>Review leave days in Zoho</strong> button will send you to
          Zoho in order to remove your vacation. After removing it, switch back
          to the app and refresh the validations by pressing the new{" "}
          <strong>refresh</strong> button.
        </Typography>
        <img src={refreshButton} />
        <Typography component="caption" variant="caption">
          You can refresh the validations after returning from Zoho
        </Typography>
        <Typography component="p">
          In order to address the <strong>missing dates</strong> error, you are
          presented with three options:
        </Typography>
        <Typography component="li">
          <strong>Submit time logs Manually</strong> - navigate to the Zoho Web
          App in order to submit the missing dates manually.
        </Typography>
        <Typography component="li">
          <strong>Add a Leave Request</strong> - navigate to the Zoho Web App in
          order to add vacation for the missing dates.
        </Typography>
        <Typography component="li">
          <strong>Upload another timesheet file</strong> - if your client
          timesheet is in a weekly format or you are working on multiple clients{" "}
          <strong>
            (remember to adjust the project from the dropdown accordingly)
          </strong>
          , there is also the option to upload another file. The new file{" "}
          <strong>must be for the same month as the previous</strong>. The newly
          generated time logs will be concatenated with the ones from your
          previous file and the validations will be rechecked.
        </Typography>
      </div>
    </div>
  );
}
