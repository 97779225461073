import React from "react";
import packageJson from "../../package.json";
import BEVersionComponent from "./BEVersionComponent";

const Footer = (props) => (
  <div className="footer">
    <p>FE version: {packageJson.version}</p>
    <BEVersionComponent apiService={props.apiService}></BEVersionComponent>
  </div>
);
export default Footer;
