import React from "react";
import "./DragAndDrop.css";
import ErrorComponent from "../components/ErrorComponent";
import ProgressBar from "./ProgressBar";
import SuccessComponent from "../components/SuccessComponent";
import { Typography, Button, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
class DragAndDrop extends React.Component {
  state = {
    dragging: false,
    tooltipText: "Cancel",
    tooltipOpen: false,
  };

  dragCounter = 0;

  static getDerivedStateFromProps(props, state) {
    if (props.isLoading) {
      return {
        tooltipText: "Cancel",
        tooltipOpen: false,
      };
    }
    return null;
  }

  handleCloseToolTip = () => {
    !this.computeToolTipCancelState() && this.setState({ tooltipOpen: false });
  };

  handleOpenToolTip = () => {
    !this.computeToolTipCancelState() && this.setState({ tooltipOpen: true });
  };

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.isStageValid() || this.isFatalError()) return;
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let file = e.dataTransfer.items[0].getAsFile();
      this.props.progressStage > 0
        ? this.props.handleExtendTimesheet(file)
        : this.props.handleDrop(file);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  handleCancelTooltip = (e) => {
    if (this.props.progressStage >= 10) {
      this.computeToolTipCancelState()
        ? this.setState({ tooltipText: "Cancel" })
        : this.setState({
            tooltipText: "Delete generated time logs as well?",
          });
    } else {
      this.props.handleCancel();
    }
  };

  isStageValid = () => {
    return this.props.progressStage == 0 || this.props.progressStage == 1;
  };

  computeToolTipCancelState() {
    return this.state.tooltipText != "Cancel";
  }

  isFatalError = () => {
    return this.props.progressStage === -1;
  };

  noProjectSelected = () => {
    return this.props.progressStage === -2;
  };

  computeClasses = () => {
    let classes = [];
    if (this.state.dragging && this.isStageValid()) {
      classes.push("dropping");
    }
    if (this.isFatalError()) {
      classes.push("fatal");
    }

    return classes.join(" ");
  };

  render() {
    return (
      <div
        className={"drag-and-drop " + this.computeClasses()}
        onDragEnter={this.handleDragIn}
        onDragLeave={this.handleDragOut}
        onDragOver={this.handleDrag}
        onDrop={this.handleDrop}
      >
        {this.props.isLoading ? (
          <ProgressBar
            uploadProgress={this.props.uploadProgress}
            progressStage={this.props.progressStage}
          />
        ) : (
          <div>
            {this.props.progressStage >= 10 ? (
              <SuccessComponent
                handleSubmitTimesheet={this.props.handleSubmitTimesheet}
                errors={this.props.validationErrors}
                handleConfirmFile={this.props.handleConfirmFile}
                progressStage={this.props.progressStage}
              />
            ) : this.props.validationErrors?.length &&
              this.props.progressStage >= 2 ? (
              <div className="ErrorComponent">
                <div className="red-circle">!</div>
                {this.props.progressStage >= 10 ? (
                  <p>
                    Your time logs have been uploaded. <span>However...</span>
                  </p>
                ) : (
                  <p>
                    Your time logs have been verified. <span>However...</span>
                  </p>
                )}
              </div>
            ) : (
              <div className="drag-and-drop__no-files">
                {!this.isFatalError() && !this.noProjectSelected() ? (
                  "Drag & drop your file inside this area"
                ) : (
                  <>
                    {this.isFatalError() && (
                      <span>
                        There are errors on your Zoho employee profile.
                        <br />
                        <strong>
                          Please submit a ticket to{" "}
                          <a
                            href={this.props.supportLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            helpcenter.principal33.com
                          </a>
                        </strong>
                        .
                      </span>
                    )}
                    {this.noProjectSelected() && (
                      <span>
                        Please select a project using the drop-down in the
                        top-left corner.
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            {this.props.progressStage == 1 && (
              <Typography
                component="h4"
                color="secondary"
                sx={{ textAlign: "center" }}
              >
                Your files so far: <span>{this.props.files.join(", ")}</span>
              </Typography>
            )}
            {this.props.validationErrors?.length > 0 && (
              <ErrorComponent
                errors={this.props.validationErrors}
                handleConfirmFile={this.props.handleConfirmFile}
                handleCloseFileValidationModal={
                  this.props.handleCloseFileValidationModal
                }
                handleCancel={this.props.handleCancel}
                confirmExtendTimesheet={this.props.confirmExtendTimesheet}
                progressStage={this.props.progressStage}
                handleRefreshValidations={this.props.handleRefreshValidations}
              ></ErrorComponent>
            )}
            {this.props.progressStage >= 1 && this.props.progressStage < 20 && (
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                }}
                onMouseEnter={this.handleOpenToolTip}
                onMouseLeave={this.handleCloseToolTip}
              >
                <Tooltip
                  title={this.state.tooltipText}
                  placement="top"
                  open={this.state.tooltipOpen}
                  onClose={this.handleCloseToolTip}
                  onOpen={this.handleOpenToolTip}
                >
                  <div>
                    {this.computeToolTipCancelState() ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{
                            fontSize: "12px",
                            padding: "0 5px",
                          }}
                          onClick={() => {
                            this.handleCancelTooltip();
                            this.props.handleCancelAndDelete();
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{
                            fontSize: "12px",
                            padding: "0 5px",
                          }}
                          onClick={() => {
                            this.handleCancelTooltip();
                            this.props.handleCancel();
                          }}
                        >
                          No
                        </Button>
                      </div>
                    ) : (
                      <IconButton onClick={this.handleCancelTooltip}>
                        <CloseIcon color="error" fontSize="large"></CloseIcon>
                      </IconButton>
                    )}
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DragAndDrop;
