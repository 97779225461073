import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { prepareData } from "../../utils/timesheetHelpers";
import { Row } from "./Row";
import { GetRows } from "./TableRow";
import { OlderTimesheets } from "./OlderTimesheets";

export default function CollapsibleTable(props) {
  const { timesheetData } = props;
  const { message } = props;
  const { submittedProperties } = props;
  // const data = prepareData(timesheetData);

  let d = new Date();
  let olderRows = Array.from(submittedProperties.entries);

  olderRows.splice(d.getMonth(), 1);
  olderRows = olderRows.filter((_) => _);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">{message}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {submittedProperties.entries[d.getMonth()] != undefined && (
            <GetRows
              row={submittedProperties.entries[d.getMonth()]}
              submittedProperties={submittedProperties}
            />
          )}
          <OlderTimesheets
            row={olderRows}
            submittedProperties={submittedProperties}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
