/**
 * Context for handling global states
 */

import React, { Component, createContext } from "react";
import { getTokens } from "../storage/storage";

export const OwnContext = createContext();
export class OwnProvider extends Component {
  constructor(props) {
    super(props);

    let data = getTokens();

    let access_token;
    let refresh_token;
    let creation_time;
    let expires_in;

    if (data && data.access_token) {
      access_token = data.access_token;
    } else {
      access_token = "";
    }

    if (data && data.refresh_token) {
      refresh_token = data.refresh_token;
    } else {
      refresh_token = "";
    }

    if (data && data.creation_time) {
      creation_time = data.creation_time;
    } else {
      creation_time = Date.now();
    }

    if (data && data.expires_in) {
      expires_in = data.expires_in;
    } else {
      expires_in = 0;
    }

    this.state = {
      isLoggedIn: false,
      access_token: access_token,
      refresh_token: refresh_token,
      creation_time: creation_time,
      expires_in: expires_in,
    };
  }

  updateState = (prevState, newState = {}) => {
    this.setState({
      ...prevState,
      ...newState,
    });
  };

  render() {
    const {
      isLoggedIn,
      access_token,
      refresh_token,
      creation_time,
      expires_in,
    } = this.state;

    return (
      <OwnContext.Provider
        value={{
          isLoggedIn,
          access_token,
          refresh_token,
          creation_time,
          expires_in,
          updateState: this.updateState,
        }}
      >
        {this.props.children}
      </OwnContext.Provider>
    );
  }
}

export default OwnProvider;
