class AppSettingsService {
  GetWebApiBaseUri() {
    return this.GetWebApiOrigin() + "/api";
  }
  GetWebApiOrigin() {
    switch (this.GetLoginRedirectUri()) {
      // lh
      case "http://localhost:3000/home":
        return "https://localhost:7046";
      // qa
      case "https://witty-sand-0afd8c103-qa.westeurope.1.azurestaticapps.net/home":
        return "https://timesheets-automation-be-qa.azurewebsites.net";
      // prod
      default:
        return "https://timesheets-automation-be.azurewebsites.net";
    }
  }
  GetMsalClientId() {
    return process.env.REACT_APP_MSAL_CLIENT_ID;
  }
  GetMsalClientScope() {
    return process.env.REACT_APP_MSAL_CLIENT_SCOPE;
  }
  GetMsalTenantAuthorityUri() {
    return process.env.REACT_APP_MSAL_TENANT_AUTHORITY_URI;
  }
  GetMsalCacheLocation() {
    return process.env.REACT_APP_MSAL_CACHE_LOCATION;
  }
  GetMsalStoreAuthInCookie() {
    let stringValue = process.env.REACT_APP_MSAL_AUTH_STATE_IN_COOKIE;

    if (stringValue.toLowerCase() === "true") {
      return true;
    } else if (stringValue.toLowerCase() === "false") {
      return false;
    } else {
      throw new Error(
        "MSAL_AUTH_STATE_IN_COOKIE setting is not a valid boolean."
      );
    }
  }

  GetZohoCompany() {
    switch (this.GetLoginRedirectUri()) {
      case "http://localhost:3000/home":
        return "principalhr";
      case "https://witty-sand-0afd8c103-qa.westeurope.1.azurestaticapps.net/home":
        return "principalhr";
      case "https://timesheet.principal33.com/home":
        return "principalhr";
      default:
        return "principalhr";
    }
  }

  GetLoginRedirectUri() {
    return window.location.origin + "/home";
  }
}

module.exports = AppSettingsService;
