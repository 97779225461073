import React from "react";
import { useState, useEffect } from "react";

function BEVersionComponent(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getAppInfo = async () => {
      await props.apiService
        .callApi("AppInfo", "", "GET")
        .then((response) => {
          setItems(response.data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    };
    getAppInfo();
  }, []);

  if (error) {
    return <p>BE unavailable</p>;
  } else if (!isLoaded) {
    return <p>Loading...</p>;
  } else {
    return <p>BE version: {items.version}</p>;
  }
}

export default BEVersionComponent;
