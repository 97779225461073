import React, { useContext, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { OwnContext } from "../context/OwnContextProvider";
import { saveAs } from "file-saver";
import LoadingSpinner from "../components/LoadingSpinner";
import "../style/Components/ExportTimesheets.scss";
import ProgressBar from "./ProgressBar";
import { Button, MenuItem, Select, TextField } from "@mui/material";

function ExportTimesheetsButton(props) {
  const context = useContext(OwnContext);
  const { access_token } = context;
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const [value, setValue] = useState(lastMonth);

  const [thereAreErrors, setThereAreErrors] = useState(false);

  const [filter, setFilter] = useState("All");

  const { exportIsLoading, setExportIsLoading } = props;

  const getTimesheetsAsZip = () => {
    setExportIsLoading(true);
    let body = {
      token: "Zoho-oauthtoken " + access_token,
      year: value.getFullYear(),
      month: value.getMonth() + 1,
      filter: filter === "All" ? null : filter,
    };
    props.apiService
      .callApi("/ExportTimesheets", body, "POST", {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        showSomethingWentWrong();
      });
  };

  const showSomethingWentWrong = () => {
    setThereAreErrors(true);
    setTimeout(() => {
      setThereAreErrors(false);
    }, 3000);
  };

  const computeEstimatedTimeMessage = () => {
    let minutes = Math.ceil(props.uploadProgress?.maximum / 20);

    if (isNaN(minutes)) {
      minutes = "...";
    }

    return `Timesheets are being exported. Estimated time: ${minutes} minute${
      minutes > 1 ? "s" : ""
    }`;
  };

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const renderContent = () => {
    if (exportIsLoading) {
      return (
        <ProgressBar
          uploadProgress={props.uploadProgress}
          progressStage={props.progressStage}
          text={computeEstimatedTimeMessage()}
        />
      );
    } else {
      if (thereAreErrors) {
        return (
          <div className="Errors">
            Sorry, but some errors were encountered. Please try again later!
          </div>
        );
      } else {
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]}
                label="Month and Year"
                minDate={new Date("2012-03-01")}
                maxDate={lastMonth}
                value={value}
                onChange={setValue}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>

            <TextField
              value={filter}
              onChange={handleChange}
              select // tell TextField to render select
              label="Employee type"
              sx={{ width: "10rem" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="CIM Contract">CIM Contract</MenuItem>
              <MenuItem value="SRL Contract">SRL Contract</MenuItem>
              <MenuItem value="PFA Contract">PFA Contract</MenuItem>
              <MenuItem value="Partner Company Contract">
                Partner Company Contract
              </MenuItem>
            </TextField>

            <Button onClick={getTimesheetsAsZip}>Export Timesheets</Button>
          </>
        );
      }
    }
  };

  return <div className="ExportTimesheets">{renderContent()}</div>;
}

export default ExportTimesheetsButton;
