import React, { useEffect, useState, useContext } from "react";
import { loginRequest } from "../authentication/zohoLogin";
import "../style/Screens/Login.scss";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import { checkIfCodeIsThere } from "../authentication/zohoLogin";
import { requestToken } from "../authentication/zohoLogin";
import { OwnContext } from "../context/OwnContextProvider";
import { setTokens } from "../storage/storage";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setServerErrorData } from "../store/reducers/beDataSlice";

export default function Login(props) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const context = useContext(OwnContext);
  const { updateState } = context;

  const dispatch = useDispatch();

  /**
   * Handling the response in requestToken and in refreshToken
   * @param {JSON} data the json data returned from fetch
   */
  const _handleResponse = (data) => {
    if (
      data["access_token"] !== undefined &&
      data["refresh_token"] !== undefined
    ) {
      updateState(context, {
        ...context,
        access_token: data["access_token"],
        refresh_token: data["refresh_token"],
        creation_time: Date.now(),
        expires_in: data["expires_in"],
        isLoggedIn: true,
      });
      setTokens({ ...context });
    }
  };

  const handleMsalLogin = async () => {
    await props.authService.signIn();
  };

  useEffect(() => {
    const code = checkIfCodeIsThere();
    const handleLoginRequest = async () => {
      let payload = new FormData();
      payload.append("RedirectUrl", window.location.origin + "/login");
      await props.apiService
        .callApi("/ZohoLogin", payload, "POST")
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    const handleRequestToken = async (codeFromZoho) => {
      let payload = new FormData();
      payload.append("RedirectUrl", window.location.origin + "/login");
      await props.apiService
        .callApi("/ZohoToken/" + codeFromZoho, payload, "POST")
        .then(async (response) => {
          if (response.data.error) {
            dispatch(setServerErrorData({ message: response.data.error }));
            navigate("../error");
          } else {
            let creation_time = Date.now();
            let data = { ...response.data, creation_time };
            console.log(data);
            setTokens(data);
            _handleResponse(data);
            await handleMsalLogin();
          }
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (!code) {
      handleLoginRequest();
    } else {
      console.log(code);
      handleRequestToken(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderErrorModal = (errorMessage) => {
    return (
      <div className="errorModal">
        <Typography
          component="h1"
          fontSize="large"
          sx={{
            textAlign: "center",
            margin: "2rem 0",
          }}
        >
          Sorry, we are having errors:{" "}
          <strong style={{ color: "red" }}>{errorMessage.toString()}</strong>
        </Typography>
        <div className="button-container">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("../")}
          >
            Go back to landing page
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="Login">
      <div className="spinner-container">
        {isLoading && (
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={70}
            thickness={5}
          />
        )}
      </div>
      {error !== "" ? renderErrorModal(error) : ""}
    </div>
  );
}
