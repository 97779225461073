/**
 * Home screen
 */

import React, { useContext, useEffect, useState } from "react";
import { OwnContext } from "../context/OwnContextProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GetTimesheetsButton from "../components/ExportTimesheetsButton.jsx";

import DragAndDrop from "../components/DragAndDrop";
import HelloMessage from "./../components/HelloMessage";
import CollapsibleTable from "../components/collapsibletable/CollapsibleTable";
import ToolDisabled from "../components/ui/ToolDisabled";
import { getSubordinateTimesheetInfo } from "../utils/timesheetHelpers";
import MonthlySummary from "../components/ui/MonthlySummary";

import { useSelector, useDispatch } from "react-redux";
import {
  setTimeLogs,
  setDeleteInfo,
} from "../store/reducers/uploadedTimeLogsSlice";
import { setErrors, pushError } from "../store/reducers/validationErrorsSlice";
import { setStage } from "../store/reducers/progressStageSlice";
import { pushFile } from "../store/reducers/filesSlice";
import { resetTimeLogsRelatedSlices } from "../store/store";
import { CircularProgress } from "@mui/material";
import { setEmployee } from "../store/reducers/beDataSlice";

export default function Home(props) {
  const context = useContext(OwnContext);
  const { access_token } = context;

  const [timeSheets, setTimeSheets] = useState(null);
  const [myTimeSheets, setMyTimeSheets] = useState(null);
  const [subordinateInfo, setSubordinateInfo] = useState({});
  const [errorMessage] = useState("");

  const [dragAndDropAllowedExtensions] = useState(["xlsx", "csv"]);
  const [dragAndDropIsLoading, setDragAndDropIsLoading] = useState(false);
  const supportLink = useSelector((state) => state.beData.supportLink);

  const [loading, setLoading] = useState(true);

  // redux store
  const uploadedTimeLogs = useSelector((state) => state.uploadedTimeLogs.value);
  const validationErrors = useSelector((state) => state.validationErrors.value);
  const uploadedFiles = useSelector((state) => state.files.value);

  // progress stages mapping:
  // 0 - initial
  // 2 - validated w errors
  // 1 - multiple files
  // 5 - refresh
  // 6 - delete
  // 10 - uploaded
  // 11** - uploaded but cannot submit
  // 12** - uploaded but some had errors
  // 20** - submitted
  // -1** - disable tool (if there are employee profile errors)
  // -2** - disable tool (no project selected)
  // ** - (will reset to 0 on refresh)
  const progressStage = useSelector((state) => state.progressStage.value);
  const deleteInfo = useSelector((state) => state.uploadedTimeLogs.deleteInfo);
  const employee = useSelector((state) => state.beData.employee);
  const dispatch = useDispatch();

  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [roles, setRoles] = useState(null);

  const [monthlySummaries, setMonthlySummaries] = useState([]);

  const authService = props.authService;
  const handleProjectNameChange = (project) => {
    setSelectedProject(project);
    dispatch(setStage(0));
  };
  // eslint-disable-next-line no-unused-vars
  let myEmail = "";
  // eslint-disable-next-line no-unused-vars
  const validClients = useSelector((state) =>
    state.uploadedTimeLogs.supportedClients.map((_) => _.id)
  );

  // let testing tudor use internal project for testing
  // if (refresh_token == "1000.def03262c54505774050fea064f70833.a0d15217a68c6518962c32d797044453") {
  //   validClients.push("26140000000148935");
  // }

  useEffect(() => {
    setLoading(true);
    resetProgressStageIfNeeded();
    getAccount()
      .then(async (_) => {
        await getEmployee().then((e) => {
          Promise.all([getTimeSheets(e), getMonthlySummary(e)]).then((_) =>
            console.log("Fetched all data.")
          );
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const resetProgressStageIfNeeded = () => {
    // user either submitted or is blocked from submitting, so reset the app on refresh
    if (progressStage === 20 || progressStage === 11 || progressStage === -1) {
      handleCancel();
    }
  };

  const getEmployee = async () => {
    return new Promise(async (resolve, reject) => {
      await props.apiService
        .callApi("/ZohoUser/employee", "", "GET")
        .then((response) => {
          if (response.status === 200) {
            dispatch(setEmployee(response.data));
            return resolve(response.data);
          }
        })
        .catch((e) => reject(e));
    });
  };

  const getMonthlySummary = async (employeeInput) => {
    let payload = {
      authorization: "Zoho-oauthtoken " + access_token,
      employee: employeeInput,
    };
    await props.apiService
      .callApi("/ZohoUser/summary", payload, "POST")
      .then((response) => {
        if (response.status === 200) {
          setProjects(response.data.projects);
          if (response.data.projects.length > 1) {
            setSelectedProject("");
            if (progressStage === 0) {
              dispatch(setStage(-2));
            }
          } else {
            setSelectedProject(response.data.projects[0]);
            dispatch(setStage(0));
          }

          setMonthlySummaries(response.data.results);
        }
      });
  };

  const getTimeSheets = async (employeeInput) => {
    let payload = {
      authorization: "Zoho-oauthtoken " + access_token,
      employee: employeeInput,
    };

    await props.apiService
      .callApi("/ZohoTimesheet", payload, "POST")
      .then(async (response) => {
        if (response.status === 200) {
          setMyTimeSheets(response.data.myTimesheets);
          setTimeSheets(response.data.subordinateTimesheets);
          const roles = await authService.getRoles();
          setSubordinateInfo(getSubordinateTimesheetInfo(response.data, roles));
        }
      })
      .catch((error) => {
        console.error(error.toString());
      });
  };

  //use this computed to check projects/tool is disabled
  function hasProjects() {
    return projects?.length > 0;
  }

  const handleCancel = () => {
    dispatch(resetTimeLogsRelatedSlices());
  };

  const handleCancelAndDelete = async () => {
    let payload = {
      Authorization: "Zoho-oauthtoken " + access_token,
      Month: deleteInfo.deleteMonth,
      Year: deleteInfo.deleteYear,
      DeleteAll: false,
    };
    setDragAndDropIsLoading(true);
    dispatch(setStage(6));
    await props.apiService
      .callApi("/ZohoTimeLog/delete", payload, "POST")
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          handleCancel();
          getMonthlySummary(employee);
        }
      })
      .finally(() => {
        setDragAndDropIsLoading(false);
      });
  };

  const handleDrop = async (file) => {
    if (!selectedProject || selectedProject === "") {
      dispatch(
        pushError({
          severity: "File",
          message: "Please select a project first",
        })
      );
      return;
    }
    let extension = file.name.split(".")[file.name.split(".").length - 1];
    if (!dragAndDropAllowedExtensions.includes(extension)) {
      dispatch(
        pushError({
          severity: "File",
          message: `File extension: .${extension} is not allowed`,
        })
      );
      return;
    }

    setDragAndDropIsLoading(true);

    const uploadTimesheetFormData = new FormData();
    uploadTimesheetFormData.append(
      "Authorization",
      "Zoho-oauthtoken " + access_token
    );
    uploadTimesheetFormData.append("ProjectId", selectedProject?.projectId);
    uploadTimesheetFormData.append("TimesheetFile", file);
    uploadTimesheetFormData.append("Employee", JSON.stringify(employee));

    // eslint-disable-next-line no-unused-vars
    let timeLogs;

    await props.apiService
      .callApi("/UploadTimesheetFile/validate", uploadTimesheetFormData, "POST")
      .then(async (response) => {
        // push file to fileList if request was successful
        if (response.status === 202) {
          dispatch(setTimeLogs(response.data.timeLogs));
          dispatch(setErrors(response.data.validationErrors));
          dispatch(setStage(2));
          dispatch(pushFile(file.name));
        }
        if (response.status === 200) {
          dispatch(pushFile(file.name));
          dispatch(setTimeLogs(response.data.timeLogs));
          timeLogs = response.data.timeLogs;
          getMonthlySummary(employee);
          dispatch(setDeleteInfo(response.data.deleteInfo));
          await handleValidateForSubmission(response.data.timeLogs);
        }
      })
      .catch((error) => {
        // bulk errors
        dispatch(setTimeLogs(error.response.data.timeLogs));
        dispatch(setDeleteInfo(error.response.data.deleteInfo));
        dispatch(setErrors(error.response.data.errors));
        if (error.response.status === 400) {
          dispatch(setStage(12));
        }
      })
      .finally(() => {
        setDragAndDropIsLoading(false);
      });
  };

  const handleConfirmFile = async () => {
    let timeLogs = uploadedTimeLogs;
    setDragAndDropIsLoading(true);
    await props.apiService
      .callApi("/UploadTimesheetFile/confirm", timeLogs, "POST")
      .then(async (response) => {
        if (response.status === 200) {
          dispatch(setTimeLogs(response.data.timeLogs));
          dispatch(setDeleteInfo(response.data.deleteInfo));
          await handleValidateForSubmission(response.data.timeLogs);
        }
      })
      .catch((error) => {
        // bulk errors
        if (error.response.status === 400) {
          dispatch(setTimeLogs(error.response.data.timeLogs));
          dispatch(setDeleteInfo(error.response.data.deleteInfo));
          dispatch(setErrors(error.response.data.errors));
          dispatch(setStage(12));
        }
      })
      .finally(() => {
        setDragAndDropIsLoading(false);
      });
  };

  const handleSubmitTimesheet = () => {
    let timeLogs = uploadedTimeLogs;
    setDragAndDropIsLoading(true);
    const payload = {
      User: timeLogs[0].user,
      FromDate: timeLogs[0].workDate,
      ToDate: timeLogs[timeLogs.length - 1].workDate,
      DateFormat: timeLogs[0].dateFormat,
      Authorization: timeLogs[0].authorization,
      ProjectId: selectedProject.projectId,
      ClientId: selectedProject.clientId,
      TimeLogs: timeLogs,
    };
    props.apiService
      .callApi("/ZohoTimesheet/submit", payload, "POST")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setErrors([]));
          dispatch(setStage(20));
        }
      })
      .finally(() => {
        setDragAndDropIsLoading(false);
      });
  };

  const confirmExtendTimesheet = () => {
    dispatch(setStage(1));
    dispatch(setErrors([]));
  };

  const handleExtendTimesheet = async (file) => {
    let extension = file.name.split(".")[file.name.split(".").length - 1];
    if (!dragAndDropAllowedExtensions.includes(extension)) {
      dispatch(
        pushError({
          severity: "File",
          message: `File extension: .${extension} is not allowed`,
        })
      );
      return;
    }

    setDragAndDropIsLoading(true);

    const uploadTimesheetFormData = new FormData();
    uploadTimesheetFormData.append(
      "Authorization",
      "Zoho-oauthtoken " + access_token
    );
    let timeLogs = uploadedTimeLogs;

    uploadTimesheetFormData.append("TimeLogs", JSON.stringify(timeLogs));
    uploadTimesheetFormData.append("TimesheetFile", file);
    uploadTimesheetFormData.append("Employee", employee);

    await props.apiService
      .callApi("/UploadTimesheetFile/extend", uploadTimesheetFormData, "POST")
      .then(async (response) => {
        // push file to fileList if request was successful
        if (response.status === 202) {
          dispatch(setTimeLogs(response.data.timeLogs));
          dispatch(setErrors(response.data.validationErrors));
          dispatch(setStage(2));
          dispatch(pushFile(file.name));
        }
        if (response.status === 200) {
          dispatch(pushFile(file.name));
          getMonthlySummary(employee);
          dispatch(setTimeLogs(response.data.timeLogs));
          dispatch(setDeleteInfo(response.data.deleteInfo));
          await handleValidateForSubmission(timeLogs);
        }
      })
      .finally(() => {
        setDragAndDropIsLoading(false);
      });
  };

  const handleRefreshValidations = async () => {
    let payload = {
      timeLogs: uploadedTimeLogs,
      employee: employee,
    };
    dispatch(setStage(5));
    setDragAndDropIsLoading(true);
    await props.apiService
      .callApi("/UploadTimesheetFile/revalidate", payload, "POST")
      .then(async (response) => {
        if (response.status === 200) {
          await handleValidateForSubmission(timeLogs);
        }
        if (response.status === 202) {
          dispatch(setErrors(response.data));
          dispatch(setStage(2));
        }
      })
      .finally(() => {
        setDragAndDropIsLoading(false);
      });
  };

  const handleValidateForSubmission = async (timeLogs) => {
    let payload = {
      timeLogs: timeLogs,
      employee: employee,
    };
    await props.apiService
      .callApi("/ZohoTimeLog/validate", payload, "POST")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setErrors([]));
          dispatch(setStage(10));
        } else {
          dispatch(setErrors(response.data.validationErrors));
          dispatch(setStage(11));
        }
        getMonthlySummary(employee);
      });
  };

  async function getAccount() {
    return new Promise(async (resolve, reject) => {
      const account = await authService.getAccount();

      const roles = await authService.getRoles();
      setRoles(roles);
      // eslint-disable-next-line no-unused-vars
      let name = account?.name?.split(" ");
      myEmail = account?.username;
      return resolve(account);
    });
  }

  function ShowGetTimesheetButton() {
    let canShow = false;
    if (roles == null) {
      getAccount();
    }
    if (roles == null) {
      return;
    }
    roles.forEach((roles) => {
      if (roles.includes("Backoffice")) {
        canShow = true;
      }
    });

    if (canShow) {
      return (
        <GetTimesheetsButton
          apiService={props.apiService}
          progressStage={progressStage}
          uploadProgress={props.uploadProgress}
          exportIsLoading={props.exportIsLoading}
          setExportIsLoading={props.setExportIsLoading}
        ></GetTimesheetsButton>
      );
    }
  }

  function ShowCollapsibleTable() {
    let canShow = false;
    if (subordinateInfo.nrOfSubordinates > 0 && timeSheets?.length > 0) {
      canShow = true;
    }
    if (!canShow) return;
    return (
      <CollapsibleTable
        timesheetData={timeSheets}
        submittedProperties={subordinateInfo}
        message="Timesheet Table"
      />
    );
  }

  return (
    <div className="home">
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={70}
            thickness={5}
          />
        </div>
      ) : (
        <div>
          {errorMessage.length === 0 ? (
            <div>
              <HelloMessage
                authService={props.authService}
                apiService={props.apiService}
                onProjectNameChange={handleProjectNameChange}
                project={selectedProject}
                projects={projects}
              ></HelloMessage>
              {monthlySummaries.length > 0 && (
                <div className="monthly-summaries">
                  {monthlySummaries.map((_) => (
                    <MonthlySummary
                      monthlySummary={_}
                      key={_.month}
                      project={selectedProject}
                    />
                  ))}
                </div>
              )}
              <ShowCollapsibleTable />
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Record ID</TableCell>
                      <TableCell align="right">Employee Name</TableCell>
                      <TableCell align="right">Employee Email</TableCell>
                      <TableCell align="right">nonbillableHours</TableCell>
                      <TableCell align="right">totalHours</TableCell>
                      <TableCell align="right">timesheetName</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myTimeSheets?.map((timeSheet) => (
                      <TableRow
                        key={timeSheet.recordId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="timeSheets">
                          {timeSheet.recordId}
                        </TableCell>
                        <TableCell align="right">
                          {timeSheet.employeeName}
                        </TableCell>
                        <TableCell align="right">
                          {timeSheet.employeeEmail}
                        </TableCell>
                        <TableCell align="right">
                          {timeSheet.nonbillableHours}
                        </TableCell>
                        <TableCell align="right">
                          {timeSheet.totalHours}
                        </TableCell>
                        <TableCell align="right">
                          {timeSheet.timesheetName}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <a
                href="https://timesheets-automation-react.azurewebsites.net/"
                target="_blank"
                rel="noreferrer"
                style={{
                  fontWeight: 600,
                  display: "block",
                  textAlign: "center",
                  margin: "20px 0",
                }}
              >
                Preview version available now! Try it out!
              </a>
              {hasProjects() ? (
                <DragAndDrop
                  handleDrop={handleDrop}
                  files={uploadedFiles}
                  isLoading={dragAndDropIsLoading}
                  validationErrors={validationErrors}
                  handleConfirmFile={handleConfirmFile}
                  uploadProgress={props.uploadProgress}
                  handleSubmitTimesheet={handleSubmitTimesheet}
                  handleCancel={handleCancel}
                  progressStage={progressStage}
                  confirmExtendTimesheet={confirmExtendTimesheet}
                  handleExtendTimesheet={handleExtendTimesheet}
                  handleRefreshValidations={handleRefreshValidations}
                  handleCancelAndDelete={handleCancelAndDelete}
                  supportLink={supportLink}
                ></DragAndDrop>
              ) : (
                <ToolDisabled validationErrors={validationErrors} />
              )}
              {ShowGetTimesheetButton()}
            </div>
          ) : (
            <p>There are errors: {errorMessage}</p>
          )}
        </div>
      )}
    </div>
  );
}
