import Button from "@mui/material/Button";
import { useState } from "react";
import { useSelector } from "react-redux";

const AppSettingsService = require("../../services//app-settings-service");
let appSettingsService = new AppSettingsService();

function Error(props) {
  const supportLink = useSelector((state) => state.beData.supportLink);

  function isSevere() {
    return (
      props.error.severity == "Severe" ||
      props.error.severity == "Reject" ||
      props.error.severity == "Employee"
    );
  }

  function disableButtons() {
    return props.error.severity == "File";
  }

  const [isBeingSolvedInZoho, setIsBeingSolvedInZoho] = useState(false);

  const navigateToZoho = (href) => {
    window.open(href, "_blank").focus();
    setTimeout(() => {
      setIsBeingSolvedInZoho(true);
    }, 1000);
  };

  const generateButton = (onClick, text) => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={onClick}
        target="_blank"
        key={text}
      >
        {text}
      </Button>
    );
  };

  const getButtonContent = () => {
    if (
      props.error.message.indexOf("will be lost") > -1 ||
      props.error.message.indexOf("are not recognized") > -1
    ) {
      return [
        {
          onClick: props.handleConfirmFile,
          href: null,
          text: "Upload time logs anyway",
        },
        {
          onClick: props.handleCancel,
          href: null,
          text: "Review your timesheet document and upload again",
        },
      ];
    }

    if (props.error.message.indexOf("overlap with the vacation") > -1) {
      return [
        {
          onClick: props.handleCancel,
          href: null,
          text: "Review your timesheet document and upload again",
        },
        {
          onClick: () =>
            navigateToZoho(
              `https://people.zoho.eu/${appSettingsService.GetZohoCompany()}/zp#leavetracker/views/list`
            ),
          text: "Review leave days in Zoho",
        },
        {
          onClick: props.handleConfirmFile,
          href: null,
          text: "Upload time logs anyway",
        },
      ];
    }

    if (props.error.severity == "Reject") {
      return [
        {
          onClick: props.handleCancel,
          href: null,
          text: "Review your timesheet document and upload again",
        },
      ];
    }

    if (props.error.severity == "Employee") {
      return [
        {
          onClick: () => window.open(supportLink, "_blank").focus(),
          text: "Open support form",
        },
      ];
    }

    if (props.error.severity === "File") {
      return [];
    }

    if (isSevere()) {
      let buttons = [
        {
          onClick: () =>
            navigateToZoho(
              `https://people.zoho.eu/${appSettingsService.GetZohoCompany()}/zp#timetracker/timelogs/addtimelog`
            ),
          text: "Submit time logs Manually",
        },
        {
          onClick: () =>
            navigateToZoho(
              `https://people.zoho.eu/${appSettingsService.GetZohoCompany()}/zp#leavetracker/views/list`
            ),
          text: "Review leave days in Zoho",
        },
      ];
      if (props.progressStage <= 2) {
        buttons.push({
          onClick: props.confirmExtendTimesheet,
          href: null,
          text: "Upload another timesheet file",
        });
      }
      return buttons;
    }

    let buttons = [
      {
        onClick: () =>
          navigateToZoho(
            `https://people.zoho.eu/${appSettingsService.GetZohoCompany()}/zp#timetracker/timelogs/calendarview`
          ),
        text: "Review existing time logs in Zoho",
      },
    ];

    if (!props.disableUpload) {
      buttons.push({
        onClick: props.handleConfirmFile,
        href: null,
        text: "Upload time logs anyway",
      });
      buttons.reverse();
    }

    return buttons;
  };

  const renderButtons = () => {
    let buttons = getButtonContent();
    return (
      <div
        style={{
          marginTop: "28px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        {buttons.map((button) => generateButton(button.onClick, button.text))}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        boxShadow: "0px 0px 6px #0000001A",
        borderRadius: 8,
        background: "rgba(255,255,255,0.5)",
        padding: 26,
        fontSize: "16px",
        lineHeight: "25px",
        position: "relative",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <span style={isSevere() ? { color: "red" } : { color: "#ff7f11" }}>
          {props.error.message.split(":")[0]}
        </span>
        {props.error.message.split(":").length > 1 &&
          props.error.message.split(":").length <= 2 && (
            <span style={{ color: "#152234" }}>
              {":" + props.error.message.split(":")[1]?.split(",").join(" |")}
            </span>
          )}
        {props.error.message.split(":").length > 2 && (
          <span style={{ color: "#152234" }}>
            {":" +
              props.error.message
                .split(":")
                .splice(1, props.error.message.split(":").length - 1)
                .join(" | ")}
          </span>
        )}
      </div>
      {!disableButtons() && renderButtons()}
      {isBeingSolvedInZoho && (
        <div className="error__solving-overlay">
          If you have resolved your issues using Zoho People, please click here
          to refresh the validations:
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Button
              onClick={props.handleRefreshValidations}
              variant="contained"
              color="secondary"
            >
              refresh
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Error;
