import Typography from "@mui/material/Typography";

function MonthlySummary(props) {
  const monthlySummary = props.monthlySummary;
  const hours =
    monthlySummary.projectSummaries.find(
      (_) => _.projectId === props.project.projectId
    )?.workedHours || 0;
  return (
    <div style={{ display: "inline-block" }}>
      <Typography component="label">{monthlySummary.month}: </Typography>
      {Math.round(hours * 10) / 10} hours
    </div>
  );
}

export default MonthlySummary;
