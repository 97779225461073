const AppSettingsService = require("../services/app-settings-service");
let appSettingsService = new AppSettingsService();

export const msalConfig = {
  auth: {
    clientId: appSettingsService.GetMsalClientId(),
    authority: appSettingsService.GetMsalTenantAuthorityUri(), // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: appSettingsService.GetLoginRedirectUri(),
    navigateToLoginRequestUrl: false,
    navigateToRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [appSettingsService.GetMsalClientScope()],
};
