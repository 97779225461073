import { getTokens, setTokens } from "../storage/storage";

const AppSettingsService = require("../services/app-settings-service");
let appSettingsService = new AppSettingsService();

let baseURL = appSettingsService.GetWebApiOrigin();
let frontEndBaseUrl = window.location.origin;

export function loginRequest(route) {
  var formdata = new FormData();
  formdata.append("RedirectUrl", frontEndBaseUrl + route);
  var requestOptions = {
    method: "POST",
    body: formdata,
  };

  return fetch(baseURL + "/api/ZohoLogin/", requestOptions)
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export function checkIfCodeIsThere() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  if (code == null) {
    return false;
  }
  return code;
}

export function checkIfCodeExists(refresh_token) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  if (code == null) {
    console.warn("code is empty");
  } else {
    if (refresh_token !== "") {
      refreshToken();
    } else {
      requestToken(code);
    }
  }
}

export const refreshToken = async () => {
  var requestOptions = {
    method: "GET",
  };

  let refresh_token = getTokens().refresh_token;
  return fetch(
    baseURL + "/api/ZohoToken/refresh/" + refresh_token,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response?.message === "Invalid refresh token.") {
        throw new Error("Invalid refresh token.");
      }
      let tokenData = {
        ...response,
        refresh_token,
        creation_time: Date.now(),
      };
      setTokens(tokenData);
      return tokenData;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export function requestToken(code, route = "/login") {
  var formdata = new FormData();
  formdata.append("RedirectUrl", frontEndBaseUrl + route);
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formdata,
  };

  return fetch(baseURL + "/api/ZohoToken/" + code, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.error) {
        throw new Error(result.error);
      }
      let creation_time = Date.now();
      setTokens({ ...result, creation_time });
      return { ...result, creation_time };
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });
}

export function getTimeSheets(access_token) {
  var formdata = new FormData();
  formdata.append("User", "all");
  formdata.append("ApprovalStatus", "all");
  formdata.append("EmployeeStatus", "users");
  formdata.append("DateFormat", "dd-MMM-yyyy");
  formdata.append("FromDate", "07-Apr-2019");
  formdata.append("ToDate", "13-Apr-2023");
  formdata.append("SIndex", "0");
  formdata.append("Limit", "200");
  formdata.append("Authorization", "Zoho-oauthtoken " + access_token);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  var url = baseURL + "/api/ZohoTimesheet/";
  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Something went wrong!");
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export function getMonthlyTimeSheets(access_token, fromDate, toDate) {
  let tokenData = getTokens();
  if (tokenData?.access_token == null) {
    if (tokenData?.refresh_token != null) {
      refreshToken();
    } else {
      loginRequest();
    }
    return;
  }
  var formdata = new FormData();
  formdata.append("User", "all");
  formdata.append("ApprovalStatus", "all");
  formdata.append("EmployeeStatus", "users");
  formdata.append("DateFormat", "dd-MMM-yyyy");
  formdata.append("FromDate", fromDate);
  formdata.append("ToDate", toDate);
  formdata.append("SIndex", "0");
  formdata.append("Limit", "200");
  formdata.append("Authorization", "Zoho-oauthtoken " + access_token);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  var url = baseURL + "/api/ZohoTimesheet/";
  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
}
