import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const OutHook = {};

export const OutHookConfigurator = () => {
  const _useNavigate = useNavigate();
  const _useDispatch = useDispatch();

  useEffect(() => {
    OutHook.useNavigate = _useNavigate;
    OutHook.useDispatch = _useDispatch;
  }, [_useNavigate, _useDispatch]);

  return null;
};

export default OutHook;
