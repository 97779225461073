import { createSlice } from "@reduxjs/toolkit";

export const filesSlice = createSlice({
  name: "files",
  initialState: {
    value: [],
  },
  reducers: {
    setFiles: (state, action) => {
      state.value = action.payload;
    },
    pushFile: (state, action) => {
      state.value.push(action.payload);
    },
    clearFiles: (state) => {
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFiles, pushFile, clearFiles } = filesSlice.actions;

export default filesSlice.reducer;
