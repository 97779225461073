import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useContext, useEffect, useState } from "react";
import { OwnContext } from "../context/OwnContextProvider";
import {
  getMonthlyTimeSheets,
  refreshToken,
} from "../authentication/zohoLogin";
import { setTokens } from "../storage/storage";

export default function MonthlyTable() {
  const context = useContext(OwnContext);
  const { access_token, refresh_token, updateState } = context;

  const [timeSheets, setTimeSheets] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const _handleResponse = (data) => {
    if (access_token !== undefined && refresh_token !== undefined) {
      updateState(context, {
        access_token: data["access_token"],
        refresh_token: data["refresh_token"],
        isLoggedIn: true,
      });
      setTokens({
        ...data,
        creation_time: Date.now(),
      });
    }
  };

  useEffect(() => {
    getMonthlyTimeSheets(access_token, "01-Feb-2022", "28-Feb-2022")
      .then((data) => {
        if (data.response.message === "Error occurred") {
          setErrorMessage(data.response.errors.message);
          if (
            data.response.errors.message ===
            "The provided OAuth token is invalid."
          ) {
            refreshToken()
              .then((data) => {
                _handleResponse(data);
              })
              .catch((error) => {
                setErrorMessage(error);
              });
          }
          return;
        }
        setTimeSheets(data.response.result);
        if (errorMessage.length !== 0) {
          setErrorMessage("");
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {errorMessage.length === 0 ? (
        timeSheets != null ? (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>FromDate</TableCell>
                    <TableCell>ToDate</TableCell>
                    <TableCell>TotalHours</TableCell>
                    <TableCell>TimesheetName</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeSheets.map((timeSheet) => (
                    <TableRow
                      key={timeSheet.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {timeSheet.employeeId}
                      </TableCell>
                      <TableCell>{timeSheet.employeeName}</TableCell>
                      <TableCell>{timeSheet.employeeEmail}</TableCell>
                      <TableCell>{timeSheet.fromDate}</TableCell>
                      <TableCell>{timeSheet.toDate}</TableCell>
                      <TableCell>{timeSheet.totalHours}</TableCell>
                      <TableCell>{timeSheet.timesheetName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <p>Loading</p>
        )
      ) : (
        <p>There are errors: {errorMessage}</p>
      )}
    </div>
  );
}
