import "./arrayExtension";
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function groupByDate(rawTimesheetData) {
  let groupedData = rawTimesheetData.groupBy((item) => {
    return [item.fromDate, item.startDate];
  });
  groupedData.sort((a, b) => {
    return (
      new Date(b[0].fromDate).getTime() - new Date(a[0].fromDate).getTime()
    );
  });
  return groupedData;
}

export function setOlderTimesheetData(groupedTimesheetData) {
  let oldData = [];
  groupedTimesheetData.forEach((groupedData) => {
    if (monthDiff(new Date(groupedData[0].fromDate), new Date())) {
      oldData.push(groupedData);
      groupedTimesheetData = groupedTimesheetData.filter(
        (item) => item !== groupedData
      );
    }
  });
  groupedTimesheetData.push({ oldData });
  return groupedTimesheetData;
}

const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => o1[p] === o2[p]);

const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

function setWeeklyTimesheetData(groupedTimesheetData) {
  let weeklyData = [];
  groupedTimesheetData.forEach((groupedData) => {
    groupedData.forEach((timesheet) => {
      if (
        dateDiffInDays(
          new Date(timesheet.fromDate),
          new Date(timesheet.toDate)
        ) <= 7
      ) {
        weeklyData.push([timesheet]);
        temp = timesheet;
      }
    });
  });

  groupedTimesheetData = groupedTimesheetData.filter((element) => {
    let isMatch = false;
    weeklyData.forEach((weeklyData) => {
      if (arraysEqual(weeklyData, element)) {
        isMatch = true;
        return;
      }
    });
    return !isMatch;
  });
  groupedTimesheetData.push({ weeklyData });
  groupedTimesheetData = groupedTimesheetData.filter((obj) => obj.length !== 0);
  return groupedTimesheetData;
}

export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months > 1;
}

export function prepareData(timesheetData) {
  let groupedData = groupByDate(timesheetData);
  groupedData = setOlderTimesheetData(groupedData);
  let oldData;
  if (groupedData[groupedData.length - 1].oldData !== undefined) {
    oldData = groupedData.splice(groupedData.length - 1);
  }
  groupedData = setWeeklyTimesheetData(groupedData);

  if (oldData.length > 0) {
    groupedData.push({ oldData: oldData[0].oldData });
  }

  return groupedData;
}

function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function filterMyTimesheets(timesheets, myEmail) {
  return timesheets.filter((obj) => obj.employeeEmail === myEmail);
}

export async function getSubordinatesTimeSheets(token, props) {
  let payload = new FormData();
  let subordinateArray;
  payload.append("Authorization", "Zoho-oauthtoken " + token);
  await props.apiService
    .callApi("/ZohoUser/GetSubordinates", payload, "POST")
    .then((response) => {
      if (response.status === 200) {
        subordinateArray = response.data;
      }
    });
  return subordinateArray;
}

export function constructSubordinateTimeSheets(subordinateArray) {
  let timeSheets = [];
  subordinateArray.forEach((subordinate) => {
    if (subordinate.Timesheet.length > 0) {
      subordinate.Timesheet.forEach((timesheet) => {
        timeSheets.push(timesheet);
      });
    }
  });
  return timeSheets;
}

export function getSubordinateTimesheetInfo(data, roleData) {
  let submittedProperties = {
    nrOfSubordinates: data.subordinates.length,
    entries: [],
  };

  roleData = roleData[0];
  let isBO = false;
  if (roleData) {
    if (roleData.filter((_) => _ === "Backoffice").length > 0) {
      isBO = true;
    }

    if (isBO && data.subordinateTimesheets.length > 0) {
      let nrOfSubordinates = data.subordinateTimesheets.map(
        (_) => _.employeeEmail
      );

      nrOfSubordinates = nrOfSubordinates.filter(function (item, pos) {
        return nrOfSubordinates.indexOf(item) === pos;
      });
      submittedProperties.nrOfSubordinates = nrOfSubordinates.length;
    } else if (data.subordinates.length > 0) {
      let subordinates = data.subordinates.map((_) => _.employemailId);
      data.subordinateTimesheets = data.subordinateTimesheets.filter((_) =>
        subordinates.includes(_.employeeEmail)
      );
    }
  }

  data.subordinateTimesheets.forEach((timesheet) => {
    let month = timesheet.fromDate.split("-")[1];
    let monthNo;
    switch (month) {
      case "Jan":
        monthNo = 0;
        break;
      case "Feb":
        monthNo = 1;
        break;
      case "Mar":
        monthNo = 2;
        break;
      case "Apr":
        monthNo = 3;
        break;
      case "May":
        monthNo = 4;
        break;
      case "Jun":
        monthNo = 5;
        break;
      case "Jul":
        monthNo = 6;
        break;
      case "Aug":
        monthNo = 7;
        break;
      case "Sep":
        monthNo = 8;
        break;
      case "Oct":
        monthNo = 9;
        break;
      case "Nov":
        monthNo = 10;
        break;
      case "Dec":
        monthNo = 11;
        break;
      default:
        break;
    }
    submittedProperties.entries.push({
      month: monthNo,
      period: timesheet.fromDate + " to " + timesheet.toDate,
      record: timesheet,
    });
  });
  submittedProperties.entries = submittedProperties.entries.reduce((c, v) => {
    c[v.month] = c[v.month] || [];
    c[v.month].push({ period: v.period, record: v.record });
    return c;
  }, []);

  return submittedProperties;
}
