import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { OwnContext } from "../context/OwnContextProvider";

import { useEffect, useContext, useState } from "react";
import {
  Paper,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { hasRole } from "../helpers/user-helper";
import LoadingSpinner from "../components/LoadingSpinner";
import { useSelector, useDispatch } from "react-redux";
import { clearApiHistory } from "../store/reducers/beDataSlice";
import LoadingText from "../components/ui/LoadingText";

const getColorCodes = (input, type) => {
  if (type === "method") {
    switch (input) {
      case "get":
        return "#74f4bc";
      case "post":
        return "#fcff51";
      case "put":
        return "#00d1ff";
      case "patch":
        return "#00d1ff";
      case "delete":
        return "#ffa197";
      default:
        return "#14223380";
    }
  }
  if (type === "status") {
    let result = "#14223380";
    switch (true) {
      case input <= 200:
        result = "#74f4bc";
        break;
      case input < 300:
        result = "#fcff51";
        break;
      case input < 500:
        result = "#ffa197";
        break;
      case input == 500:
        result = "red";
        break;
    }
    return result;
  }
};

function DebugPage(props) {
  const employee = useSelector((state) => state.beData.employee);
  const apiHistory = useSelector((state) => state.beData.apiHistory);
  const dispatch = useDispatch();

  const [months, setMonths] = useState([]);
  const [selectedTimeLogsMonth, setSelectedTimeLogsMonth] = useState("");
  const [user, setUser] = useState("");
  const [timesheets, setTimesheets] = useState([]);

  const context = useContext(OwnContext);
  const { access_token, refresh_token, expires_in, creation_time } = context;

  const [snackbarVariant, setSnackbarVariant] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [account, setAccount] = useState();
  const [deleteAll, setDeleteAll] = useState(false);

  const [deleteSelectedDate, setDeleteSelectedDate] = useState(new Date());

  const [loading, setLoading] = useState(false);

  const [deleteTimeLogsResponse, setDeleteTimeLogsResponse] = useState(<></>);

  const checkIfIsAdmin = async () => {
    const account = await props.authService.getAccount();
    console.log(hasRole(account, "Admin"));
    setIsAdmin(hasRole(account, "Admin"));
  };

  const getAccount = () => {
    props.authService.getAccount().then((res) => {
      setAccount(res);
      setIsAdmin(hasRole(res, "Admin"));
    });
  };

  useEffect(() => {
    generateMonths();
    getTimeSheets();
    getAccount();
  }, []);

  const buttonStyle = {
    borderWidth: "2px",
    textDecoration: "none",
    minWidth: "0",
    lineHeight: "25px",
    padding: "3px 21px",
    borderRadius: "10px",
    fontSize: 16,
    "&:hover": { borderWidth: "2px" },
  };

  const generateMonths = () => {
    var generatedMonths = [];
    for (let i = -2; i < 3; i++) {
      var d = new Date();
      d.setMonth(d.getMonth() + i);
      generatedMonths.push(d);
    }
    setMonths(generatedMonths);
    setSelectedTimeLogsMonth(generatedMonths[2]);
  };

  const getTimeSheets = async () => {
    let payload = {
      authorization: "Zoho-oauthtoken " + access_token,
      employee: employee,
    };

    await props.apiService
      .callApi("/ZohoTimesheet", payload, "POST")
      .then(async (response) => {
        if (response.status === 200) {
          let data = [];
          response.data.myTimesheets.forEach((t) => {
            data.push({ ...t, checked: false });
          });

          setTimesheets(data);
        }
      })
      .catch((error) => {
        console.error(error.toString());
      });
  };

  const handleChange = (event) => {
    setSelectedTimeLogsMonth(event.target.value);
  };

  const handleUserInput = (event) => {
    setUser(event.target.value);
  };

  const handleTimesheetCheck = (event, timesheet) => {
    const newTimesheets = timesheets;
    let changedTimesheet = newTimesheets.find(
      (_) => _.recordId === timesheet.recordId
    );
    changedTimesheet.checked = !changedTimesheet.checked;
    setTimesheets([...newTimesheets]);
  };

  const deleteTimeLogs = async () => {
    let payload = {
      authorization: "Zoho-oauthtoken " + access_token,
      month: deleteSelectedDate.getMonth() + 1,
      year: parseInt(
        deleteSelectedDate.toLocaleDateString(undefined, { year: "numeric" })
      ),
      user: user,
      deleteAll: deleteAll === "true" ? true : false,
    };
    setDeleteTimeLogsResponse(<LoadingText />);
    await props.apiService
      .callApi("/ZohoTimeLog/delete", payload, "POST")
      .then((response) => {
        if (response.status === 200) {
          setDeleteTimeLogsResponse(
            <Alert severity="success" sx={{ width: "100%", mt: "24px" }}>
              {response.data.message}
            </Alert>
          );
          return;
        }
        if (response.status === 202) {
          setDeleteTimeLogsResponse(
            <Alert severity="warning" sx={{ width: "100%", mt: "24px" }}>
              {response.data.message}
            </Alert>
          );
          return;
        }
      })
      .catch((error) => {
        console.error(error.toString());
      });
  };

  const deleteTimesheets = async () => {
    let filteredTimesheets = timesheets.filter((_) => _.checked);
    if (filteredTimesheets.length == 0) {
      showSnackbar("error", "No timesheets selected");
      return;
    }
    let payload = {
      Ids: filteredTimesheets.map((_) => _.recordId).join(","),
      Authorization: "Zoho-oauthtoken " + access_token,
    };
    setLoading(true);
    await props.apiService
      .callApi("/ZohoTimesheet/delete", payload, "POST")
      .then((response) => {
        if (response.status === 200) {
          showSnackbar(
            "success",
            `Successfully deleted ${filteredTimesheets.length} timesheets`
          );
          deleteCheckedTimesheets();
        }
      })
      .catch((error) => {
        console.error(error.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCheckedTimesheets = () => {
    let newTimesheets = timesheets;
    newTimesheets = newTimesheets.filter((_) => !_.checked);
    setTimesheets([...newTimesheets]);
  };

  const showSnackbar = (variant, message) => {
    setSnackbarMessage(message);
    setSnackbarVariant(variant);
    if (!snackbarOpen) setSnackbarOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            background: "rgba(255,255,255,0.8)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: "1",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div
          style={{
            maxWidth: 1368,

            flex: "1",
            height: "100%",
          }}
        >
          <div>
            <Typography
              variant="h5"
              color="#00d1ff"
              fontWeight="300"
              sx={{ my: "20px" }}
            >
              Disclaimer: Your data is stored only on the client side (your
              browser). Principal does not store any of this data in a database
              or server. Data related to Zoho is stored externally in Zoho's
              systems.
            </Typography>
          </div>

          <div>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  Authentication Information{" "}
                  <span style={{ color: "red", textTransform: "uppercase" }}>
                    (Warning! Sensitive information!)
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6">Access token:</Typography>
                <Typography variant="body1">
                  Zoho-oauthtoken{" "}
                  <span style={{ color: "#5353ba" }}>{access_token}</span>
                </Typography>
                <Typography variant="h6">Refresh token:</Typography>
                <Typography variant="body1" color="#5353ba">
                  {refresh_token}
                </Typography>
                <Typography variant="h6">Token expires at:</Typography>
                <Typography variant="body1" color="#5353ba">
                  {new Date(creation_time + 3.6e6).toLocaleString()}
                </Typography>
                <Typography variant="h6">User Principal Name:</Typography>
                <Typography variant="body1" color="#5353ba">
                  {account?.username}
                </Typography>
                <Typography variant="h6">Your roles:</Typography>
                <Typography variant="body1" color="#5353ba">
                  {account?.idTokenClaims["roles"]?.length > 0
                    ? account?.idTokenClaims["roles"].join(", ")
                    : "Regular user"}
                </Typography>
                <Accordion sx={{ mt: 2 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Cookies:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color="#5353ba">
                      {document.cookie}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Local storage:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body1"
                      color="#5353ba"
                      sx={{ overflowY: "scroll", wordBreak: "break-all" }}
                    >
                      {JSON.stringify({ ...localStorage })}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Session storage:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body1"
                      color="#5353ba"
                      sx={{ wordBreak: "break-all" }}
                    >
                      {JSON.stringify({ ...sessionStorage })}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  API Calls History{" "}
                  <span style={{ color: "red", textTransform: "uppercase" }}>
                    (Warning! Sensitive information!)
                  </span>
                </Typography>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ ...buttonStyle, ml: "auto", mr: "12px" }}
                  onClick={() => dispatch(clearApiHistory())}
                >
                  Clear
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                {apiHistory?.map((entry, i) => (
                  <Accordion key={i}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span
                        style={{
                          color: getColorCodes(entry.method, "method"),
                          fontWeight: "bold",
                          marginRight: 10,
                          width: 40,
                        }}
                      >
                        {entry.method.toUpperCase()}
                      </span>
                      <span>{entry.route}</span>
                      <span
                        style={{
                          color: getColorCodes(entry.status, "status"),
                          display: "inline-block",
                          marginLeft: "auto",
                          fontWeight: "bold",
                        }}
                      >
                        {entry.status}
                      </span>
                      {entry.duration && (
                        <span
                          style={{
                            marginLeft: 12,
                            width: 65,
                          }}
                        >
                          {Math.round((entry.duration * 100) / 1000) / 100}s
                        </span>
                      )}
                      {entry.time && (
                        <span style={{ width: 180 }}>
                          {new Date(entry.time).toLocaleString()}
                        </span>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "16px",
                      }}
                    >
                      <div style={{ minWidth: 0 }}>
                        <Typography fontWeight={500} color="#5353ba">
                          Request
                        </Typography>
                        <div>
                          <Typography fontSize="small">Body</Typography>
                          <div>
                            <pre
                              style={{
                                background: "#f7f7f7",
                                borderRadius: 12,
                                padding: 4,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                                maxHeight: 400,
                                overflowY: "scroll",
                              }}
                            >
                              {JSON.stringify(entry.request.body, null, 2)}
                            </pre>
                          </div>
                          <Typography fontSize="small">Headers</Typography>
                          <div>
                            <pre
                              style={{
                                background: "#f7f7f7",
                                borderRadius: 12,
                                padding: 4,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                                maxHeight: 400,
                                overflowY: "scroll",
                              }}
                            >
                              {JSON.stringify(entry.request.headers, null, 2)}
                            </pre>
                          </div>
                        </div>
                      </div>
                      <div style={{ minWidth: 0 }}>
                        <Typography fontWeight={500} color="#5353ba">
                          Response
                        </Typography>
                        <div>
                          <Typography fontSize="small">Body</Typography>
                          <pre
                            style={{
                              background: "#f7f7f7",
                              borderRadius: 12,
                              padding: 4,
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                              maxHeight: 400,
                              overflowY: "scroll",
                            }}
                          >
                            {JSON.stringify(entry.response.body, null, 2)}
                          </pre>
                        </div>
                      </div>
                      {entry.notes && (
                        <div style={{ gridColumn: "1 / -1" }}>
                          <Typography fontSize="small">Notes</Typography>
                          {entry.notes}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Delete time logs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={["year", "month"]}
                      label="Month and Year"
                      minDate={new Date("2019-01-01")}
                      maxDate={new Date()}
                      value={deleteSelectedDate}
                      onChange={(value) => setDeleteSelectedDate(value)}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Delete all time logs?
                    </FormLabel>
                    <RadioGroup
                      row
                      value={deleteAll}
                      onChange={(e) => setDeleteAll(e.target.value)}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Only generated by this app"
                      />
                    </RadioGroup>
                  </FormControl>
                  {isAdmin && (
                    <FormControl>
                      <TextField
                        label="User email"
                        variant="outlined"
                        email="true"
                        helperText="Leave empty for your current user"
                        value={user}
                        onInput={handleUserInput}
                      />
                    </FormControl>
                  )}
                </div>
                <div style={{ marginTop: 24 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={deleteTimeLogs}
                    sx={buttonStyle}
                  >
                    Delete time logs
                  </Button>
                </div>
                {deleteTimeLogsResponse}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Delete timesheets</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl component="fieldset" variant="standard">
                  {timesheets && timesheets?.length > 0 ? (
                    <>
                      <FormLabel component="legend">
                        Select and delete timesheets
                      </FormLabel>
                      <FormGroup>
                        {timesheets.map((timesheet) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={timesheet.checked}
                                onChange={(event) =>
                                  handleTimesheetCheck(event, timesheet)
                                }
                                name={timesheet.recordId}
                              />
                            }
                            label={`RecordId: ${timesheet.recordId} - ${timesheet.employeeName} - ${timesheet.timesheetName}`}
                            key={timesheet.recordId}
                          />
                        ))}
                      </FormGroup>
                    </>
                  ) : (
                    <h5>No timesheets found.</h5>
                  )}
                  <div style={{ marginTop: 36 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={deleteTimesheets}
                      sx={buttonStyle}
                      disabled={!timesheets?.length}
                    >
                      Delete timesheets
                    </Button>
                  </div>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={snackbarVariant}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DebugPage;
