// export interface ILoadingTextProps {
//     message?: string;
// }

// const LoadingText:React.FC<ILoadingTextProps> = ({message}) => {
//     return (
//         <div>

//         </div>
//     )
// }

import "style/Components/LoadingText.scss";

const LoadingText = ({ message }) => {
  return (
    <div className="loading-text">
      {message ? message : "Hang on . . ."}&nbsp;&nbsp;&nbsp;{" "}
      <span className="spinner-console" />
    </div>
  );
};

export default LoadingText;
