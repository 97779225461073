import axios from "axios";
import { refreshToken } from "../authentication/zohoLogin";
import store from "../store/store";
import { resetState } from "../store/store";
import { setErrors } from "../store/reducers/validationErrorsSlice";
import { setStage } from "../store/reducers/progressStageSlice";
import { setEmployee } from "../store/reducers/beDataSlice";
import OutHook from "../components/misc/OutHookConfigurator";
import {
  setServerErrorData,
  addApiHistoryEntry,
} from "../store/reducers/beDataSlice";

import { clearTokens } from "../storage/storage";

const AppSettingsService = require("./app-settings-service");
let appSettingsService = new AppSettingsService();

const exceptionRoutes = ["AppInfo"];

class ApiService {
  baseUrl;
  authenticatedApi;

  authService;
  constructor(authService) {
    this.baseUrl = appSettingsService.GetWebApiBaseUri();
    this.authService = authService;
    this.authenticatedApi = axios.create({
      baseURL: this.baseUrl,
    });
    this.authenticatedApi.defaults.headers.common[
      "Access-Control-Allow-Origin"
    ] = "*";
  }

  async callApi(url, body, method, myHeaders, myOptions) {
    return new Promise(async (resolve, reject) => {
      let token;

      let apiCall = {
        route: url,
        method: method.toLowerCase(),
        status: null,
        request: {
          headers: {
            ...myHeaders,
          },
          body: {},
        },
        response: {
          body: {},
        },
        notes: "",
        duration: 0,
        time: new Date().getTime(),
      };

      if (body instanceof FormData) {
        body.forEach((value, key) => {
          if (value instanceof File) {
            apiCall.request.body[key] = "Binary File";
            return;
          }
          apiCall.request.body[key] = value;
        });
      } else {
        apiCall.request.body = body;
      }

      var startTime = performance.now();

      await this.authService
        .getToken()
        .then((result) => {
          token = result;
        })
        .catch((e) => {
          token = null;
        });
      let headers = { ...myHeaders };
      if (token) {
        headers.Authorization = "Bearer " + token;
      }
      return this.authenticatedApi
        .request({
          method: method.toLowerCase(),
          url: url,
          data: body,
          headers: { ...headers },
          ...myOptions,
        })
        .catch(async (error) => {
          console.error(
            "%cAn error occurred accessing the API:",
            "font-weight: bold"
          );
          apiCall.status = error?.response?.status;
          apiCall.response.body = error?.response?.data;
          if (error?.response?.status === 401) {
            //Invalid OauthToken
            await refreshToken()
              .then(async (_) => {
                // refresh employee data
                if (body.employee) {
                  await this.callApi("/ZohoUser/employee", "", "GET").then(
                    (response) => {
                      if (response.status === 200) {
                        store.dispatch(setEmployee(response.data));
                        body.employee = response.data;
                        console.log("Employee data refreshed.");
                      }
                    }
                  );
                }

                if (body.authorization) {
                  body.authorization = "Zoho-oauthtoken " + _.access_token;
                }
                if (body instanceof FormData) {
                  body.set(
                    "Authorization",
                    "Zoho-oauthtoken " + _.access_token
                  );
                }
                if (body[0] !== undefined && body[0].authorization) {
                  let timeLogs = JSON.parse(JSON.stringify(body));
                  timeLogs = timeLogs.map((t) => {
                    t.authorization = "Zoho-oauthtoken " + _.access_token;
                    return t;
                  });
                  return resolve(
                    this.callApi(url, timeLogs, method, myHeaders, myOptions)
                  );
                }
                if (
                  body.timeLogs !== undefined &&
                  body.timeLogs !== null &&
                  body.timeLogs.length > 0
                ) {
                  let bodyCopy = JSON.parse(JSON.stringify(body));
                  bodyCopy.timeLogs = bodyCopy.timeLogs.map((t) => {
                    t.authorization = "Zoho-oauthtoken " + _.access_token;
                    return t;
                  });
                  apiCall.notes = "Automatic refresh token attempt";
                  return resolve(
                    this.callApi(url, bodyCopy, method, myHeaders, myOptions)
                  );
                }
              })
              .catch((e) => {
                // refresh token is invalid. log user out
                clearTokens();
                store.dispatch(resetState());
                this.authService.signOut();
                return reject(e);
              });
            return resolve(
              this.callApi(url, body, method, myHeaders, myOptions)
            );
          }
          if (error?.response?.data !== undefined) {
            if (error.response.data[0]?.messages) {
              let errors = [];
              error.response.data.forEach((element) => {
                errors.push({
                  severity: "File",
                  message: `An error occurred uploading time log ${
                    element.data.jobName
                  } from date ${
                    element.data.workDate
                  } to Zoho. Error: ${element.messages.join(", ")}`,
                });
              });
              store.dispatch(setErrors(errors));
              return reject(error);
            }
            if (
              error.response.data[0]?.severity &&
              error.response?.status === 400
            ) {
              store.dispatch(setErrors(error.response.data));
              if (
                error.response.data.filter((_) => _?.severity === "Employee")
                  ?.length > 0
              ) {
                store.dispatch(setStage(-1));
              }
              return reject(error);
            }
          }
          if (error?.response?.status === 500) {
            store.dispatch(setServerErrorData(error.response.data));
            OutHook.useNavigate("../error");
            return reject(error);
          }

          if (error?.response?.status === 204) {
            return reject(error);
          }
          // connection refused
          if (error.response === null || error.response === undefined) {
            store.dispatch(
              setServerErrorData({ message: "Backend could not be reached." })
            );
            apiCall.status = 500;
            apiCall.notes = "Backend unreachable";
            OutHook.useNavigate("../error");
          }

          return reject(error);
        })
        .then((response) => {
          apiCall.status = response?.status;
          apiCall.response.body = response?.data;
          if (response?.data instanceof ArrayBuffer) {
            apiCall.response.body = "Binary file";
          }
          return resolve(response);
        })
        .finally(() => {
          var endTime = performance.now();
          apiCall.duration = endTime - startTime;

          if (!exceptionRoutes.includes(url)) {
            store.dispatch(addApiHistoryEntry(apiCall));
          }
        });
    });
  }

  async retryCallWithRefresh(url, body, method) {
    await refreshToken();
    return this.callApi(url, body);
  }
}

export default ApiService;
