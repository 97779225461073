import { LinearProgress } from "@mui/material";

function ProgressBar(props) {
  const normalise = (uploadProgress) => {
    if (!uploadProgress) return null;
    return (uploadProgress.progress * 100) / uploadProgress.maximum;
  };

  function renderMessage() {
    let progressStage = props.progressStage;
    let text;
    if (progressStage <= 1) text = "Your file is being verified";
    if (progressStage <= 1 && isDeterminate())
      text = "Your time logs are being uploaded";
    if (progressStage == 2) text = "Your time logs are being uploaded";
    if (progressStage == 5) text = "Your time logs are being revalidated";
    if (progressStage == 6) text = "Your time logs are being deleted";
    if (progressStage == 10) text = "Your timesheet is being submitted";

    // Override
    if (props.text) text = props.text;

    return (
      <div
        style={{
          display: "inline-block",
          fontSize: "20px",
          color: "var(--pc-darkgrey)",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {text}
      </div>
    );
  }

  const isDeterminate = () => {
    return props.uploadProgress && (props.progressStage <= 2 || props.text);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        {renderMessage()}
        <LinearProgress
          color="secondary"
          variant={isDeterminate() ? "determinate" : "indeterminate"}
          value={normalise(props.uploadProgress)}
          sx={{
            width: "100%",
            boxShadow: "inset 0px 2px 6px #0000001A, 0px 1px 0px #FFFFFF",
            borderRadius: 12,
            height: "23px",
            maxWidth: "320px",
            margin: "auto",
          }}
        />
        {isDeterminate() && (
          <div
            style={{
              display: "inline-block",
              fontSize: "14px",
              color: "var(--pc-turq)",
              textAlign: "center",
            }}
          >
            {Math.ceil(normalise(props.uploadProgress))}%
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgressBar;
