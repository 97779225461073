import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/auth-config";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./routes/NotFound";
import Login from "./routes/Login";
import OwnProvider from "./context/OwnContextProvider";
import MonthlyTable from "./screens/Table";
import { ErrorBoundary } from "react-error-boundary";
import { Navbar } from "./components/Navbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import ApiService from "./services/api-service";
import AuthService from "./services/auth-service";

import store from "./store/store";
import { Provider } from "react-redux";
import { saveState } from "./store/storage/browserStorage";
import { debounce } from "debounce";

import ErrorFallback from "./components/ErrorFallback";
import LandingPage from "./screens/LandingPage";
import HelpPage from "./screens/HelpPage";

import PrivateRoute from "./routes/PrivateRoute";
import DebugPage from "./screens/DebugPage";
import Footer from "./components/Footer";
import { OutHookConfigurator } from "./components/misc/OutHookConfigurator";
import ErrorPage from "./screens/ErrorPage";

const msalInstance = new PublicClientApplication(msalConfig);

const authService = new AuthService(msalInstance);

const apiService = new ApiService(authService);

const theme = createTheme({
  palette: {
    primary: {
      main: "#112336",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00d1ff",
      contrastText: "#fff",
    },
    accent: {
      main: "#5353ba",
      contrastText: "#fff",
    },
    error: {
      main: "#FF0000",
      contrastText: "#fff",
    },
    success: {
      main: "#27E028",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff7f11",
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    htmlFontSize: 20,
    allVariants: {
      color: "#112336",
    },
    fontSize: 16,
    lineHeight: 20,
    fontFamily: [
      "Saira",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "none",
      textDecoration: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderWidth: "2px",
          minWidth: "0",
          lineHeight: "25px",
          padding: "3px 21px",
          borderRadius: "10px",
          fontSize: 16,
          "&:hover": { borderWidth: "2px" },
        },
      },
    },
  },
});

store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      localStorage.clear();
    }}
  >
    <Provider store={store}>
      <OwnProvider>
        <MsalProvider instance={msalInstance}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Navbar authService={authService}></Navbar>
              <OutHookConfigurator />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexGrow: "1",
                }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute
                        authService={authService}
                        skippable="true"
                        publicRoute="true"
                      >
                        <LandingPage />
                      </PrivateRoute>
                    }
                  ></Route>
                  <Route
                    path="/home"
                    element={
                      <PrivateRoute authService={authService}>
                        <App
                          apiService={apiService}
                          authService={authService}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <Login
                        authService={authService}
                        apiService={apiService}
                      />
                    }
                  ></Route>
                  <Route
                    path="/monthly"
                    element={
                      <PrivateRoute authService={authService}>
                        <MonthlyTable />
                      </PrivateRoute>
                    }
                  ></Route>
                  <Route path="*" element={<NotFound />}></Route>
                  <Route
                    path="/help"
                    element={
                      <PrivateRoute
                        authService={authService}
                        publicRoute="true"
                      >
                        <HelpPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/debug"
                    element={
                      <PrivateRoute
                        authService={authService}
                        // hasRole={['Admin']}
                      >
                        <DebugPage
                          apiService={apiService}
                          authService={authService}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/error"
                    element={
                      <PrivateRoute
                        authService={authService}
                        publicRoute="true"
                      >
                        <ErrorPage />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </div>
              <Footer apiService={apiService} />
            </BrowserRouter>
          </ThemeProvider>
        </MsalProvider>
      </OwnProvider>
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
