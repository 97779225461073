import * as React from "react";

import Button from "@mui/material/Button";

import { useEffect, useState, useContext } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { OwnContext } from "../context/OwnContextProvider";
import { clearTokens } from "../storage/storage";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "../store/store";

import { Link } from "react-router-dom";
import { setSupportLink } from "../store/reducers/beDataSlice";

const AppSettingsService = require("../services//app-settings-service");
let appSettingsService = new AppSettingsService();

export const Navbar = (props) => {
  const authService = props.authService;

  const [account, setAccount] = useState(null);
  const [hideAccount, setHideAccount] = useState(false);

  const [showAdmin, setShowAdmin] = useState(false);

  const location = useLocation();

  const context = useContext(OwnContext);
  const {
    access_token,
    refresh_token,
    expires_in,
    creation_time,
    updateState,
  } = context;

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname == "/") {
      setHideAccount(true);
    }
    async function getAccount() {
      await authService.getAccount().then((account) => {
        setAccount(account);
        let supportLink =
          "https://helpcenter.principal33.com/portal/en/newticket?category=Zoho%20People&subCategory=Configuration";
        dispatch(setSupportLink(supportLink));
      });
    }
    const getRoles = async () => {
      let _ = await authService.getRoles();
      _ = _[0];
      if (_?.filter((role) => role === "Admin").length > 0) {
        // Sufficient roles
        setShowAdmin(true);
      }
    };
    Promise.all([getAccount(), getRoles()]);
  }, []);

  useEffect(() => {
    if (window.location.pathname == "/") {
      setHideAccount(true);
    } else {
      setHideAccount(false);
    }
  }, [location]);

  function handleLogin() {
    authService.signIn();
  }

  function handleLogout() {
    updateState(context, {
      ...context,
      access_token: null,
      refresh_token: null,
      creation_time: null,
      expires_in: null,
      isLoggedIn: false,
    });
    clearTokens();
    dispatch(resetState());
    authService.signOut();
  }

  return (
    <AppBar position="fixed">
      <Toolbar justify="space-between">
        <div
          style={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <a href="/home">
            <img
              className="nav__logo"
              src="https://www.principal33.com/media/p33-new-logo--white--cropped.png"
              alt="logo"
            />
          </a>
          {showAdmin && (
            <a href="/debug">
              <Button sx={{ color: "white" }} size="small">
                Debug
              </Button>
            </a>
          )}
        </div>

        {!hideAccount ? (
          <div className="nav__right">
            {account ? (
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: "white" }}
              >
                Welcome, {account.name}
              </Typography>
            ) : (
              ""
            )}
            {!account ? (
              <Button color="inherit" size="small" onClick={handleLogin}>
                Log in
              </Button>
            ) : (
              <Button color="inherit" size="small" onClick={handleLogout}>
                Sign out
              </Button>
            )}
          </div>
        ) : (
          ""
        )}
      </Toolbar>
    </AppBar>
  );
};
