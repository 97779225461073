import React, { useContext, useState, useEffect } from "react";
import "../style/Screens/LandingPage.scss";
import { useNavigate } from "react-router-dom";
import { OwnContext } from "../context/OwnContextProvider";
import { refreshToken } from "../authentication/zohoLogin";
import { setTokens } from "../storage/storage";
import LoadingSpinner from "../components/LoadingSpinner";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const LandingPage = (props) => {
  const context = useContext(OwnContext);
  const {
    access_token,
    refresh_token,
    expires_in,
    creation_time,
    updateState,
  } = context;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldNavigateDirectlyToLogin, setShouldNavigateDirectlyToLogin] =
    useState(false);

  useEffect(() => {
    if (!errorMessage) {
      return;
    }

    const timer = setTimeout(() => {
      setErrorMessage("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [errorMessage]);

  const _handleResponse = (data) => {
    if (
      data["access_token"] !== undefined &&
      data["refresh_token"] !== undefined
    ) {
      updateState(context, {
        ...context,
        access_token: data["access_token"],
        refresh_token: data["refresh_token"],
        creation_time: Date.now(),
        expires_in: data["expires_in"],
        isLoggedIn: true,
      });
      setTokens({ ...context });
    }
  };

  const navigateToHome = () => {
    if (access_token && refresh_token) {
      // We need to check if the token is valid
      var creationDate = new Date(creation_time);
      creationDate.setHours(creationDate.getHours() + 1);
      if (creationDate.getTime() > Date.now()) {
        // The token is valid
        navigate("./home");
      } else {
        // The token is expired, we need to refresh the token
        setIsLoading(true);
        refreshToken()
          .then((response) => {
            _handleResponse(response);
            setIsLoading(false);
            navigate("./home");
          })
          .catch((e) => {
            // Failed to fetch
            setIsLoading(false);
            setErrorMessage(e.message);
            setShouldNavigateDirectlyToLogin(true);
          });
      }
    } else {
      // We need to navigate to login screen
      navigate("./login");
    }
  };

  const renderMessageAndButton = () => {
    return (
      <div className="landing-page">
        <div>
          <Typography variant="h3" component="div" gutterBottom>
            Hello <span style={{ color: "var(--pc-turq)" }}>#principaler</span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Welcome to the Zoho timesheet automation tool
          </Typography>
          <p></p>
        </div>

        <div className="button-container">
          <Button
            variant="contained"
            onClick={() => {
              shouldNavigateDirectlyToLogin
                ? navigate("./login")
                : navigateToHome();
            }}
          >
            Log In
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate("./help");
            }}
            target="_blank"
          >
            help
          </Button>
        </div>
        <div style={{ marginTop: 20 }}>
          <a
            href="https://timesheets-automation-react.azurewebsites.net/"
            target="_blank"
            rel="noreferrer"
            style={{ fontWeight: 600 }}
          >
            Preview version available now! Try it out!
          </a>
        </div>
      </div>
    );
  };

  const renderLoadingSpinnerAndText = () => {
    return (
      <>
        <div className="loading-container">
          <p>Trying to refresh token!</p>

          <LoadingSpinner></LoadingSpinner>
        </div>
      </>
    );
  };

  const renderErrorMessage = (errorMessage) => {
    return (
      <>
        <div className="error-message">{errorMessage}</div>
      </>
    );
  };

  return (
    <div className="LandingPage">
      <div>
        {isLoading ? renderLoadingSpinnerAndText() : renderMessageAndButton()}
      </div>

      {errorMessage ? renderErrorMessage(errorMessage) : ""}
    </div>
  );
};

export default LandingPage;
