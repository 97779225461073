import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  value: [],
  deleteInfo: null,
  supportedClients: [
    {
      name: "Aily",
      id: "26140000000148059",
      test: false,
    },
    {
      name: "EON",
      id: "26140000000148065",
      test: false,
    },
    {
      name: "IBM DE",
      id: "26140000000826042",
      test: false,
    },
    {
      name: "powercloud",
      id: "26140000000293517",
      test: false,
    },
    {
      name: "Fake Aily",
      id: "37872000000149472",
      test: true,
    },
    {
      name: "Fake EON",
      id: "38459000000153043",
      test: true,
    },
  ],
};

export const uploadedTimeLogsSlice = createSlice({
  name: "uploadedTimeLogs",
  initialState: defaultState,
  reducers: {
    setTimeLogs: (state, action) => {
      state.value = action.payload;
    },
    clearTimeLogs: (state) => {
      state.value = [];
    },
    setDeleteInfo: (state, action) => {
      state.deleteInfo = action.payload;
    },
    resetTimeLogs: (state) => {
      Object.assign(state, defaultState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTimeLogs, clearTimeLogs, setDeleteInfo, resetTimeLogs } =
  uploadedTimeLogsSlice.actions;

export default uploadedTimeLogsSlice.reducer;
