import { createSlice } from "@reduxjs/toolkit";

export const beDataSlice = createSlice({
  name: "beData",
  initialState: {
    serverErrorData: null,
    supportLink: "",
    employee: null,
    apiHistory: [],
  },
  reducers: {
    setServerErrorData: (state, action) => {
      state.serverErrorData = action.payload;
    },
    setSupportLink: (state, action) => {
      state.supportLink = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    addApiHistoryEntry: (state, action) => {
      state.apiHistory.push(action.payload);
    },
    clearApiHistory: (state) => {
      state.apiHistory = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setServerErrorData,
  setSupportLink,
  setEmployee,
  addApiHistoryEntry,
  clearApiHistory,
} = beDataSlice.actions;

export default beDataSlice.reducer;
