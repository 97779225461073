/**
 * Handling the local storage
 */

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const TOKENS_DATA = "tokens_data";

const storage = window.localStorage;

export function getAccessToken() {
  return storage.getItem(ACCESS_TOKEN);
}

export function setAccessToken(token) {
  storage.setItem(ACCESS_TOKEN, token);
}

export function getRefreshToken() {
  return storage.getItem(REFRESH_TOKEN);
}

export function setRefreshToken(token) {
  storage.setItem(REFRESH_TOKEN, token);
}

export function setTokens(data) {
  if (data && data.access_token !== "" && data.refresh_token !== "") {
    storage.setItem(TOKENS_DATA, JSON.stringify(data));
  }
}

export function getTokens() {
  return JSON.parse(storage.getItem(TOKENS_DATA));
}

export function clearTokens() {
  storage.clear(TOKENS_DATA);
}
