import { loginRequest } from "../config/auth-config";

const AppSettingsService = require("./app-settings-service");
let appSettingsService = new AppSettingsService();

class AuthService {
  msalInstance;
  account;

  constructor(msalInstance, account) {
    this.msalInstance = msalInstance;
    this.account = account;
  }

  async signIn() {
    await this.msalInstance
      .loginRedirect(loginRequest)
      .catch((e) => {
        console.error(e);
      })
      .then((_) => {
        this.getAccount();
      });
  }

  signOut() {
    this.msalInstance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  async getToken() {
    return new Promise(async (resolve, reject) => {
      await this.getAccount()
        .then(async (account) => {
          if (!account) return reject(null);
          let tokenRequest = {
            account: account,
            scopes: [appSettingsService.GetMsalClientScope()],
          };
          return tokenRequest;
        })
        .then(async (tokenRequest) => {
          await this.msalInstance
            .acquireTokenSilent(tokenRequest)
            .then((authResponse) => {
              return resolve(authResponse.accessToken);
            })
            .catch((error) => {
              return reject(error);
            });
        });
    });
  }

  async getAccount() {
    await timeout(100);

    if (
      this.msalInstance.browserStorage.browserStorage.windowStorage.length < 2
    ) {
      console.error("NO ACCOUNT");
      return null;
    }

    return await new Promise(async (resolve) => {
      let account = await this.msalInstance.getAllAccounts()[0];
      if (!account) {
        await timeout(100);
        return resolve(this.getAccount());
      }
      return resolve(account);
    });
  }

  async getRoles() {
    return await this.getAccount().then((_) => {
      if (!_) return [];
      return [
        _.idTokenClaims.roles?.map((_) => _.substring(10, _.length - 5)) || [
          "IC",
        ],
      ];
    });
  }
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default AuthService;
